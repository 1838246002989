.media-object__list {
    padding: 0;
    margin: 0;
    list-style: none;

    @include clearfix;
}

.media-object__item {
    padding: 10px 0;
}

.media-object__list:after {
    clear: both;
}
