.embedded-map {
    position: relative;
    height: calc(88vw * 0.5);

    @include screen-768 {
        height: calc(92vw * 0.5);
    }

    @include screen-1200 {
        height: calc(1140px * 0.5);
    }

    &:after {
        @supports(mix-blend-mode: hue) {
            background-color: $color-primary;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            pointer-events: none;
            opacity: 0.2;
            mix-blend-mode: hue;
            transition:
                mix-blend-mode 250ms ease,
                filter 250ms ease,
                opacity 250ms ease;
            z-index: 1;
        }
    }

    &:hover,
    &:focus {
        &:after {
            @supports(mix-blend-mode: hue) {
                opacity: 0;
            }
        }

        iframe {
            @supports(filter: grayscale(0.2)) {
                filter: grayscale(0.2);
            }
        }
    }

    &:focus-within {
        &:after {
            @supports(mix-blend-mode: hue) {
                opacity: 0;
            }
        }

        iframe {
            @supports(filter: grayscale(0.2)) {
                filter: grayscale(0.2);
            }
        }
    }

    iframe {
        display: block;
        height: 100%;
        width: 100%;
        transition:
            filter 250ms ease,
            mix-blend-mode 250ms ease,
            opacity 250ms ease;

        @supports(filter: grayscale(0.75)) {
            filter: grayscale(0.75);
        }
    }
}
