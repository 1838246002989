.sidebar-widget,
#room-template .sidebar-widget {
    margin-bottom: 40px;

    &__title,
    h4 {
        margin: 0 0 15px;
        font-size: 18px;
        text-transform: uppercase;
    }
}

.sidebar-document-gallery {
    --gutter-width: 1rem;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));

    margin-bottom: 24px;

    &__item {
        margin-bottom: var(--gutter-width);
    }

    .button {
        display: block;
        padding-left: 16px;
        padding-right: 16px;
    }
}


.sidebar-contact {
    h3{
        font-weight:300;
        font-size:18px;
        color:#bcbec0;
        margin-top:0;
        margin-bottom:5px;
    }

    p,
    p > a {
        margin:0;
        color:#1e1e1e;
    }

    a {
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    .fusion-text{
        margin:0 0 40px;
        position:relative;
        padding-left:65px;
    }

    .fusion-text:before{
        position:absolute;
        top:5px;
        left:0;
        content:"\f3c5";
        font-family:'Font Awesome 5 Free';
        font-size:16px;
        font-weight:900;
        display:inline-block;
        font-style:normal;
        font-variant:normal;
        text-rendering:auto;
        line-height:1;
        color:#1e1e1e;
        margin-right:10px;
    }

    .fusion-text.contact-address:before{
        content:"\f3c5";
    }

    .fusion-text.contact-phone:before{
        content:"\f095";
    }

    .fusion-text.contact-email:before{
        content:"\f0e0";
        top:0;
    }

    .fusion-text:nth-child(4){
        padding-left:0;
    }

    .fusion-text:nth-child(4):before{
        display:none;
    }

    .fusion-text:nth-child(4) h4{
        margin:0;
        color:#1e1e1e;
        display:inline-block;
        vertical-align:middle;
    }
}

.sidebar-widget.sidebar-social-list {
    display: flex;
    align-items: center;

    h3 {
        margin: 0;
        color: #1e1e1e;
        display: inline-block;
        font-size: 18px;
    }

    .social-list {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-left: 1em;
    }

    .social-list__item {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            height: 20px;
            width: 20px;

            @include svg-contents {
                fill: $color-grey-07;
            }
        }
    }
}
