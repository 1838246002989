.slider {
    position: relative;
    width: 100%;

    &__track {
        @include css-slider;
    }

    &__dots {
        position: absolute;
        top: auto;
        bottom: 0;
        left: var(--default-container-gutter);
        right: auto;
        height: 24px;
        z-index: 1;
        display: flex;

        a {
            padding: 12px;
            position: relative;

            span {
                @include vis-hidden;
            }

            &:before {
                content: "";
                background-color: rgba($color-white, 0.6);
                box-shadow: 0 0 0 2px transparent;
                height: 8px;
                width: 8px;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition:
                    background-color 200ms ease,
                    box-shadow 200ms ease;
            }

            &:hover:before {
                background-color: $color-primary;
                box-shadow: 0 0 0 2px $color-white;
            }

            &:focus:before {
                background-color: $color-primary;
                box-shadow: 0 0 0 2px $color-white;
            }

            &.active:before {
                background-color: $color-primary;
                transition-delay: 50ms;
                box-shadow: 0 0 0 2px $color-white;
            }
        }
    }

    &__arrows {
        -webkit-appearance: unset;
        position: absolute;

        background: transparent;
        line-height: normal;
        outline: none;
        padding: 0;
        border: 0;

        width: 50px;
        height: 50px;
        z-index: calc(#{$header-index} - 1);
        top: 50%;
        margin-top: -25px;
        position: absolute;
        transition:
            border .3s ease-in-out,
            border-radius .2s ease-in-out,
            background-color .3s ease-in-out,
            transform .2s ease-in-out;

        border-radius: 25px;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -16px;
            width: 26px;
            height: 26px;
            border: 3px solid transparent;
            border-top: 3px solid rgba(255,255,255,0.75);
        }

        &:hover {
            transform: scaleX(1.2) scaleY(.7);
        }

        &.previous {
            left: 0;

            &:after {
                left: 20px;
                transform: rotate(-45deg);
                border-left: 3px solid rgba(255,255,255,0.75);
            }

            &:focus,
            &:active {
                &:after {
                    border-top-color: $color-white;
                    border-left-color: $color-white;
                }
            }
        }

        &.next {
            right: 0;

            &:after {
                right: 20px;
                transform: rotate(45deg);
                border-right: 3px solid rgba(255,255,255,0.75);
            }

            &:focus,
            &:active {
                &:after {
                    border-top-color: $color-white;
                    border-right-color: $color-white;
                }
            }
        }

        * {
            pointer-events: none;
        }

        svg {
            @supports(filter: drop-shadow(0 1px 4px rgba($color-black, 25%))) {
                filter: drop-shadow(0 1px 4px rgba($color-black, 25%));
            }

            @include svg-contents {
                stroke: $color-grey-02;
            }
        }
    }
}

[aria-label="gallery"]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px; /* compensates for 2px border */
}

.slider__item img {
    object-fit: cover;
}
