.content-block {
    display: grid;
    gap: 40px;

    @include screen-992 {
        grid-template-columns: 1fr;
        gap: 4%;
    }

    &.full-width-media {

    }

    &.half-media-right {
        @include screen-992 {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.half-media-left {
        @include screen-992 {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.sidebar-media-right {
        @include screen-992 {
            grid-template-columns: 3fr 2fr;
        }

    }

    &.sidebar-media-left {
        @include screen-992 {
            grid-template-columns: 2fr 3fr;
        }
    }
}
