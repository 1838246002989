:root {
    --header_top_bg_color: #00737a;
    --snav_color: #ffffff;
    --snav_height: 44px;
}

.fusion-secondary-header {
    background-color: var(--header_top_bg_color);
    color: var(--snav_color);
    font-size: 12px;
    border: none;

    @media screen and (max-width:991px) {
        display:none!important
    }

    a {
        color:#fff;

        &:hover,
        &:focus,
        &:active {
            color:#fff
        }
    }

    .container{
        font-size:14px
    }

    .fusion-mobile-nav-holder {
        width:80%;
        margin:0 auto;
    }
}

.fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-icon-only-link .menu-title,
.fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-icon-only-link .menu-title {
    display:none
}


// html:not(.avada-has-site-width-percent) #main,
// html:not(.avada-has-site-width-percent) .fusion-footer-copyright-area,
// html:not(.avada-has-site-width-percent) .fusion-footer-widget-area,
// html:not(.avada-has-site-width-percent) .fusion-page-title-bar,
// html:not(.avada-has-site-width-percent) .fusion-secondary-header {
//     padding-left:30px;padding-right:30px
// }

html.avada-has-site-width-percent body .fusion-secondary-header,
html.avada-has-site-width-percent body.avada-responsive .fusion-secondary-header {
    padding-left:0;padding-right:0
}

html.avada-has-site-width-percent.avada-has-site-width-100-percent .fusion-body .fusion-secondary-header {
    padding-left:30px;padding-right:30px
}

html.avada-has-site-width-percent .avada-responsive.layout-boxed-mode .fusion-secondary-header .fusion-row{
    max-width:none;padding-left:10px;padding-right:10px
}

.fusion-mobile-menu-design-flyout .fusion-secondary-header,
.fusion-mobile-menu-design-modern .fusion-secondary-header{
    padding:0
}

.fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-row,
.fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-row{
    padding-left:0;padding-right:0
}

.fusion-body .fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-alignleft,
.fusion-body .fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-alignright,
.fusion-body .fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-alignleft,
.fusion-body .fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-alignright{
    text-align:center
}

.fusion-mobile-menu-design-modern .fusion-secondary-header{
    padding-left:0!important;padding-right:0!important
}

.fusion-secondary-header{
    background-color:#00737a
}

.fusion-secondary-header .fusion-row{
    display:block
}

.fusion-secondary-header .fusion-alignleft{
    margin-right:0
}

.fusion-secondary-header .fusion-alignright{
    margin-left:0
}
body.fusion-body .fusion-secondary-header .fusion-alignright>*{
    float:none
}
body.fusion-body .fusion-secondary-header .fusion-alignright .fusion-social-links-header .boxed-icons{
    margin-bottom:5px
}

.fusion-mobile-menu-design-classic .fusion-secondary-header{
    padding:10px
}

.fusion-mobile-menu-design-classic .fusion-secondary-header .fusion-mobile-nav-holder{
    margin-top:0
}

.fusion-secondary-header .fusion-row{
    padding-left:0!important;padding-right:0!important
}

.fusion-mobile-menu-design-flyout,
.fusion-mobile-menu-design-modern {
    .fusion-social-links-header {
        max-width:100%;
        text-align:center;
        margin-top:10px;
        margin-bottom:8px;

        a {
            margin-right:20px;margin-bottom:5px
        }
    }
}

/* ---------------------------------
Social links
----------------------------------- */
.fusion-social-links-header {
    margin-top:10px;
    margin:0;
    text-align:right;

    .fusion-social-networks-wrapper{
        margin:0!important;
    }

    .fusion-social-networks {
        a {
            margin:0;
            display:inline-block;
            line-height:var(--snav_height);
            vertical-align:middle;
            padding:0 12px;
            font-size:16px;

            &:hover{
                color:#FFFFFF!important;opacity:1!important;background:#bcbec0;
            }
        }

        a.custom img{
            max-height:16px;
        }

        &.boxed-icons {
            .fusion-social-network-icon{
                color:#fffcfc;
            }

            .fusion-social-network-icon:not(.custom){
                background-color:#e8e8e8;border-color:#e8e8e8;border-radius:4px;
            }

            .boxed-icons a{
                padding:8px;width:calc(16px + (2 * (8px)) + 2px);
            }

            .boxed-icons a.custom{
                top:-8px;
            }

            .boxed-icons a.custom img{
                max-height:calc(16px + (2 * (8px)) + 2px);
            }

            .boxed-icons a img{
                max-height:calc(16px + (2 * (8px)) + 2px);
            }

            a.fusion-social-network-icon.fusion-custom.fusion-icon-custom{
                padding:0;background-color:transparent;border:none;border-radius:0;box-shadow:none;
            }

            a.fusion-social-network-icon.fusion-custom.fusion-icon-custom img{
                position:relative;
            }
        }

        .fusion-social-network-icon {
            color:#fffcfc;
        }
    }
}

.fusion-mobile-menu-design-flyout .fusion-social-links-header,
.fusion-mobile-menu-design-modern .fusion-social-links-header{
    max-width:100%;text-align:center;margin-top:10px;margin-bottom:8px
}

.fusion-mobile-menu-design-flyout .fusion-social-links-header a,
.fusion-mobile-menu-design-modern .fusion-social-links-header a{
    margin-right:20px;margin-bottom:5px
}

.fusion-header-wrapper .fusion-mobile-menu-design-classic .fusion-social-links-header{
    max-width:100%;margin-top:5px;text-align:center;margin-bottom:5px
}

body.fusion-body .fusion-secondary-header .fusion-alignright .fusion-social-links-header .boxed-icons{
    margin-bottom:5px
}

.fusion-header-wrapper .fusion-mobile-menu-design-classic {
    .fusion-social-links-header{
        max-width:100%;margin-top:5px;text-align:center;margin-bottom:5px
    }
}

#side-header {
    .fusion-social-links-header{
        margin:0;

        .fusion-social-networks a{
            margin-bottom:20px
        }
    }

    .fusion-social-networks.boxed-icons .fusion-social-network-icon {
        margin-bottom:10px
    }
}


/* ---------------------------------
Contact Info
----------------------------------- */
.fusion-contact-info ul{
    list-style-type:none;margin:0;padding:0
}

.fusion-contact-info ul li{
    display:inline-block;padding:0 20px
}

.fusion-contact-info ul li a{
    color:#FFF
}

.fusion-contact-info ul li i{
    color:#FFF;margin:0 8px 0 0
}

.fusion-contact-info ul li:first-child{
    padding-left:0
}

.fusion-contact-info ul li:last-child{
    padding-right:0;border-right:none
}
