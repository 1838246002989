.review-item {
    display: block;

    &__speech-bubble {
        position: relative;
        background-color: $color-white;
        background-image: url(../assets/icon_tripadvisor.svg);
        background-position: right 8px bottom 8px;
        background-repeat: no-repeat;
        padding: 40px 40px 48px 56px;
        box-shadow: rgb($color-black, 10%) 2px 2px 2px 0;

        @include screen-768 {
            flex: 1 1 auto;
        }

        &:before {
            content: '\201C';

            display: block;
            position: absolute;

            top: 40px;
            left: 60px;
            bottom: auto;
            right: auto;
            width: 24.6094px;
            height: 96px;

            color: rgb(187,187,187);

            perspective-origin: 12.2969px 48px;
            transform-origin: 12.2969px 48px;

            font: normal normal normal normal 60px / 96px Georgia,serif;
            margin: -25px 0 0 -40px;
        }

        &:after {
            content: "";

            display: block;
            position: absolute;
            top: auto;
            bottom: -30px;
            left: 56px;
            height: 30px;
            width: 30px;

            perspective-origin: 15px 15px;
            transform-origin: 15px 15px;
            filter: drop-shadow(rgba(0,0,0,.0980392) 2px 2px 1px);
            border-top: 30px solid $color-white;
            border-right: 30px solid transparent;
            border-bottom: none;
            border-left: none;
        }

        blockquote {
            all: unset;

            &:after {
                content: none;
            }
        }
    }

    &__score {
        display: flex;
        margin-bottom: 0.5em;

        .mfp-content & {
            margin-bottom: 1em;
        }

        .score {
            display: block;
            position: relative;
            width: 18px;
            height: 18px;
            border: 2px solid $color-grey-03;
            border-radius: 100%;
            background-color: #fff;
            margin-right: 1px;

            &:after {
                content: "";
                position: absolute;
                left: 3px;
                right: 3px;
                top: 3px;
                bottom: 3px;
                border-radius: 100%;
                background-color: $color-grey-03;
            }

            &.filled {
                border-color: #34E0A1;

                &:after {
                    background-color: #34E0A1;
                }
            }
        }
    }

    &__attribution {
        line-height: normal;
        color: rgb(85,85,85);
        display: block;
        text-transform: uppercase;
        padding: 8px 0 0 86px;
        margin-bottom: 0;
        font-size: var(--centi-font-size);
        font-family: $primary-font;

        .mfp-content & {
            margin-top: 1em;
            padding-left: 0;
        }

        &__name {
            display: block;
            font-weight: $fw-normal;
        }

        &__date {

        }
    }
}




.reviews-slider {
    &__slider {
        position: relative;
        margin: 0 -15px;

        &__track {
            @include css-slider;
        }

        .slider__dots {
            position: static;
            justify-content: center;
            margin-top: 24px;

            a {
                &:before {
                    background-color: transparent;
                    border: 1px solid #bcbec0;
                    box-shadow: none;
                }

                &.active:before {
                    background-color: #bcbec0;
                }

                &:hover:before {
                    background-color: $color-black;
                    border-color: $color-black;
                }

                &:focus:before {
                    background-color: $color-black;
                    border-color: $color-black;
                }
            }
        }

        .slider__arrows {
            vertical-align: middle;
            display: inline-block;

            border: none;
            background: #bcbec0;
            color: $color-black;

            cursor: pointer;
            text-align: center;
            font-size: 13px;

            top: 0;
            bottom: 0;
            background: rgba(0,0,0,0.25);
            height: auto;
            width: var(--default-container-gutter);
            border-radius: 0;
            margin-top: 0;

            @include screen-1400 {
                border: 1px solid #bcbec0;
                background-color: $color-white;
                color: $color-white;
                border-radius: 32px;
                height: 32px;
                width: 32px;
                top: 50%;
                bottom: auto;

                margin-top: -16px;
            }

            &:after {
                content: none;
            }

            &.previous {
                right: calc(100% - 15px);
                left: auto;
                background: linear-gradient(to left, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);

                @include screen-1400 {
                    right: calc(100% + 14px);
                    background: #bcbec0;
                }
            }

            &.next {
                left: calc(100% - 15px);
                right: auto;
                background: linear-gradient(to right, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);

                @include screen-1400 {
                    left: calc(100% + 14px);
                    background: #bcbec0;
                }
            }

            &:hover {
                transform: none;
            }
        }

        &__item {
            flex: 0 0 100%;
            flex-shrink: 0;
            scroll-snap-align: start;
            padding: 0 15px;

            @include screen-768 {
                flex: 0 0 50%;
                display: flex;
                flex-direction: column;
            }

            @include screen-992 {
                flex: 0 0 33.33333%;
            }
        }
    }
}
