
.hero-banner {
    position: relative;

    &__content {
        position: relative;
        background: $color-grey-09;
        color: $color-white;
    }

    &__slider.splide {
        margin-bottom: 0;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($color-black, 0.1);
            z-index: 0;

            @supports(mix-blend-mode: multiply) {
                background: rgba($color-black, 0.2);
                mix-blend-mode: multiply
            }
        }
    }

    .splide__pagination {
        bottom: 0;

        @include screen-768 {
            bottom: 40px;
        }
    }

    &__item {
        display: block;
        flex: 0 0 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
        position: relative;
        overflow: hidden;
        height: 80vw;

        @include screen-360 {
            height: 66vw;
        }

        @include min-orientation($bp992, $bp1400, landscape) {
            height: calc(100vh - var(--snav_height));
        }

        @include min-orientation($bp992, $bp1400, portrait) {
            height: 80vw;
        }

        @include min-orientation($bp1400, $bp1600, landscape) {
            height: calc(100vh - var(--snav_height));
        }

        @include min-orientation($bp1400, $bp1600, portrait) {
            height: 60vw;
        }

        @include screen-1600 {
            height: calc(100vh - var(--snav_height));
        }

        @include screen-1920 {
            height: calc(1080px - var(--snav_height));
        }

        img {
            @supports(object-fit: cover) {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    &__text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding-left: var(--default-container-gutter);
        padding-right: var(--default-container-gutter);
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include screen-menu-desktop {
            padding-top: var(--header-height);
        }

        @include screen-992 {
            max-width: 860px;
            padding-left: 0;
            padding-right: 0;
            left: calc(50vw - 420px);
            right: calc(50vw - 420px);
        }
    }

    &__subtitle {
        text-transform: uppercase;
        font-family: $accent-font;
        font-weight: $fw-semibold;
        font-size: 1.6rem;
        margin-bottom: 20px;

        @include screen-768 {
            font-size: 2.4rem
        }

        @include screen-992 {

        }
    }


    &__title {
        display: block;
        line-height: 1;
        text-transform: uppercase;
        color: inherit;
        font-family: $accent-font;
        font-weight: $fw-semibold;
        letter-spacing: 0.8px;
        font-size:20px;
        margin-top: 0;
        margin-bottom:40px;

        @include screen-768 {
            font-size: 42px;
        }

        @include screen-992 {
            font-size: 72px;
        }
    }

    &__cta {
        position: relative;
        z-index: 1;
        pointer-events: all;

        a {
            @include button-outline($color-primary, $color-white);
        }
    }

    .accom-search {
        color: $color-grey-06;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
        position: relative;
        box-shadow: none;
        border-color: $color-primary;
        padding: $spacing*2 var(--default-container-gutter) var(--default-container-gutter);

        @include screen-560 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(3, auto);
            padding-bottom: $spacing*2;
        }

        @include screen-768 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, auto);
        }

        @include screen-992 {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, auto);
            padding: $spacing*2;
            border-color: $color-grey-02;
            position: absolute;
            bottom: 0;
            right: 2vw;
            width: 25vw;
            box-shadow: $spacing $spacing $spacing*2 rgba($color-black, .2);
        }

        @include screen-1400 {
            width: 360px;
        }

        @include screen-1600 {
            right: 5vw;
        }

        &__item--title {
            margin-left: calc(var(--default-container-gutter) * -1);
            margin-right: calc(var(--default-container-gutter) * -1);

            @include screen-992 {
                margin-left: -$spacing*2;
                margin-right: -$spacing*2;
            }
        }
    }
}

.hero-awards {
    position: absolute;
    top: calc(var(--header-height) + 40px);
    left: var(--default-container-gutter);
    z-index: 1;
    display: none;

    @include screen-1200 {
        display: block;
    }

    @include screen-1400 {
        left: calc(var(--default-container-gutter) - 32px);
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: top;
    }

    &__item {
        position: relative;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 112px;
        width: 112px;
        margin: 0 4px;

        @include screen-1400 {
            flex-basis: 144px;
            width: 144px;
            margin: 0 8px;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            width: 1.5px;
            height: 160px;
            background-color: $color-black;
        }

        figcaption {
            @include vis-hidden;
        }
    }

    img {
        display: block;
        width: 100%;
    }
}
