.fusion-contact-info {
    line-height:44px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            padding: 0 20px;

            &:first-child {
                padding-left: 0;
            }

            a {
                color: #FFF;
            }

            i {
                color: #FFF;
                margin: 0 8px 0 0;
            }
        }
    }
}
