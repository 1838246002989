.page-header {
	text-align: center;
	margin-bottom: var(--section-small);

	&__subtitle {
		font-size: var(--body-font-size);
	}

	&__heading {
		display: block;
        line-height: 1;
		font-size: var(--giga-font-size);
		margin-bottom: 0;
	}
}

.blog-post-controller .page-header {
	text-align: left;
	margin-bottom: 0;

	&__heading {
		font-size: var(--h1-font-size);
	}
}
