:root {
    --card-gutter-width: 40px;
}

@include screen-768 {
    :root {
        --card-gutter-width: 56px;
    }
}

@include screen-992 {
    :root {
        --card-gutter-width: 4%;
    }
}

.card__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-right: calc((var(--card-gutter-width) * 0.5) * -1);
    margin-left: calc((var(--card-gutter-width) * 0.5) * -1);
    justify-content: center;
}

.card__item {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: calc((var(--card-gutter-width) * 0.5));
    padding-left: calc((var(--card-gutter-width) * 0.5));
    margin-bottom: var(--card-gutter-width);

    @include screen-560 {
        flex-basis: 50%;
        max-width: 50%;
    }

    @include screen-768 {
        flex-basis: 33.333333%;
        max-width: 33.333333%;
    }

    @include screen-992 {
        flex-basis: 25%;
        max-width: 25%;
    }
}

.card {
    background: $color-white;
    padding:0;
    display:flex;
    flex-direction:column;
    height: 100%;
    text-align: center;
    color: inherit;

    &:hover,
    &:focus,
    &:active {
        .home-service-icon.fontawesome-icon.circle-yes {
            color: #00737a!important;
            border-color: #00737a!important;
            -moz-box-shadow: 5px 5px 5px rgba(0,0,0,.1);
            -webkit-box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
            box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
        }

        svg {
            @include svg-contents {
                fill: $color-primary;
            }
        }
    }
}

.card__content {
    display:flex;
    flex-direction:column;
    flex-grow:1;
    order:1;
}

.card__description {
    color: $color-grey-09;
    margin-bottom: 0;
}

.card__heading {
    color: $color-grey-09;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: 1em;
    bottom: 0;
    font-weight: $fw-light;
}

// ------

.fontawesome-icon {
    display: inline-block;
    float: left;
    margin-right: 10px;
}

.fusion-fa-align-center {
    display: block;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

i.fontawesome-icon.circle-yes {
    background-color: #fff;
}

.fontawesome-icon.circle-yes {
    border-radius: 50%;
    background-clip: inherit;
    border-width: 1px;
    border-style: solid;
    background-color: #333;
    color: #fff;
    text-align: center;
    margin-top: 2px;
}

.home-service-icon.fontawesome-icon.circle-yes {
    border-color: #bcbec0;
    background-color: #ffffff;
    font-size: 52.8px;
    line-height: 105.6px;
    height: 105.6px;
    width: 105.6px;
    color: #bcbec0;
}


.services__title {
    margin-bottom: var(--card-gutter-width);
}
