@charset "UTF-8";
/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
:root {
  --body-font-size: 1.4rem;
  --h1-font-size: 3.2rem;
  --h1-line-height: normal;
  --h2-font-size: 2.6rem;
  --h2-line-height: normal;
  --h3-font-size: 2.1rem;
  --h3-line-height: normal;
  --h4-font-size: 1.8rem;
  --h4-line-height: normal;
  --h5-font-size: 1.8rem;
  --h5-line-height: normal;
  --h6-font-size: 1.6rem;
  --h6-line-height: normal;
  /* [02] */
  --giga-font-size: 8rem;
  --mega-font-size: 6.4rem;
  --kilo-font-size: 5.6rem;
  --milli-font-size: 1rem;
  --centi-font-size: 1.2rem;
}

/*
Basic system font stacks
*/
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url(//fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(//fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxP.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: local("Rubik"), local("Rubik-Regular"), url(//fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nBrXk.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: local("Rubik Medium"), local("Rubik-Medium"), url(//fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Eyjmmd8WU.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v16/jizaRExUiTo99u79D0KEwA.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0Uw.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFV0Uw.ttf) format("truetype");
}
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@-webkit-keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@-webkit-keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Mixins ---- */
:root {
  --border-width: 2px;
  --border-radius: 6px;
}

/* --------------------------------
Text Link
----------------------------------- */
/* --------------------------------
Sizes
----------------------------------- */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* -----------------------------
Visibility Utilities
------------------------------*/
/* -----------------------------
Clearfix
------------------------------*/
/* -----------------------------
SVG
------------------------------*/
/* -----------------------------
CSS Slider
------------------------------*/
/* ---- Lists ---- */
.fusion-contact-info {
  line-height: 44px;
}
.fusion-contact-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.fusion-contact-info ul li {
  display: inline-block;
  padding: 0 20px;
}
.fusion-contact-info ul li:first-child {
  padding-left: 0;
}
.fusion-contact-info ul li a {
  color: #FFF;
}
.fusion-contact-info ul li i {
  color: #FFF;
  margin: 0 8px 0 0;
}

/* ------------------------------------------------------
**** Blocks
------------------------------------------------------ */
.hero-banner {
  position: relative;
}
.hero-banner__content {
  position: relative;
  background: #1e1e1e;
  color: #ffffff;
}
.hero-banner__slider.splide {
  margin-bottom: 0;
  position: relative;
}
.hero-banner__slider.splide::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 0;
}
@supports (mix-blend-mode: multiply) {
  .hero-banner__slider.splide::after {
    background: rgba(0, 0, 0, 0.2);
    mix-blend-mode: multiply;
  }
}
.hero-banner .splide__pagination {
  bottom: 0;
}
@media screen and (min-width: 48em) {
  .hero-banner .splide__pagination {
    bottom: 40px;
  }
}
.hero-banner__item {
  display: block;
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
  height: 80vw;
}
@media screen and (min-width: 22.5em) {
  .hero-banner__item {
    height: 66vw;
  }
}
@media screen and (min-width: 62em) and (max-width: 87.5em) and (orientation: landscape) {
  .hero-banner__item {
    height: calc(100vh - var(--snav_height));
  }
}
@media screen and (min-width: 62em) and (max-width: 87.5em) and (orientation: portrait) {
  .hero-banner__item {
    height: 80vw;
  }
}
@media screen and (min-width: 87.5em) and (max-width: 100em) and (orientation: landscape) {
  .hero-banner__item {
    height: calc(100vh - var(--snav_height));
  }
}
@media screen and (min-width: 87.5em) and (max-width: 100em) and (orientation: portrait) {
  .hero-banner__item {
    height: 60vw;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner__item {
    height: calc(100vh - var(--snav_height));
  }
}
@media screen and (min-width: 120em) {
  .hero-banner__item {
    height: calc(1080px - var(--snav_height));
  }
}
@supports (object-fit: cover) {
  .hero-banner__item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.hero-banner__text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-left: var(--default-container-gutter);
  padding-right: var(--default-container-gutter);
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 62em) {
  .hero-banner__text {
    padding-top: var(--header-height);
  }
}
@media screen and (min-width: 62em) {
  .hero-banner__text {
    max-width: 860px;
    padding-left: 0;
    padding-right: 0;
    left: calc(50vw - 420px);
    right: calc(50vw - 420px);
  }
}
.hero-banner__subtitle {
  text-transform: uppercase;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 20px;
}
@media screen and (min-width: 48em) {
  .hero-banner__subtitle {
    font-size: 2.4rem;
  }
}
.hero-banner__title {
  display: block;
  line-height: 1;
  text-transform: uppercase;
  color: inherit;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 40px;
}
@media screen and (min-width: 48em) {
  .hero-banner__title {
    font-size: 42px;
  }
}
@media screen and (min-width: 62em) {
  .hero-banner__title {
    font-size: 72px;
  }
}
.hero-banner__cta {
  position: relative;
  z-index: 1;
  pointer-events: all;
}
.hero-banner__cta a {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--border-radius);
  border-width: var(--border-width);
  border-style: solid;
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  background: transparent;
  border-color: #ffffff;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.hero-banner__cta a svg {
  height: 1em;
  width: 1em;
}
.hero-banner__cta a svg path,
.hero-banner__cta a svg circle,
.hero-banner__cta a svg ellipse,
.hero-banner__cta a svg rect,
.hero-banner__cta a svg line,
.hero-banner__cta a svg polygon {
  fill: #ffffff;
}
.hero-banner__cta a:hover, .hero-banner__cta a:focus, .hero-banner__cta a:active {
  background-color: #00737a;
  border-color: #ffffff;
  color: #ffffff;
}
.hero-banner__cta a:hover svg path,
.hero-banner__cta a:hover svg circle,
.hero-banner__cta a:hover svg ellipse,
.hero-banner__cta a:hover svg rect,
.hero-banner__cta a:hover svg line,
.hero-banner__cta a:hover svg polygon, .hero-banner__cta a:focus svg path,
.hero-banner__cta a:focus svg circle,
.hero-banner__cta a:focus svg ellipse,
.hero-banner__cta a:focus svg rect,
.hero-banner__cta a:focus svg line,
.hero-banner__cta a:focus svg polygon, .hero-banner__cta a:active svg path,
.hero-banner__cta a:active svg circle,
.hero-banner__cta a:active svg ellipse,
.hero-banner__cta a:active svg rect,
.hero-banner__cta a:active svg line,
.hero-banner__cta a:active svg polygon {
  fill: #ffffff;
}
.hero-banner .accom-search {
  color: #636363;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  position: relative;
  box-shadow: none;
  border-color: #00737a;
  padding: 16px var(--default-container-gutter) var(--default-container-gutter);
}
@media screen and (min-width: 35em) {
  .hero-banner .accom-search {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 48em) {
  .hero-banner .accom-search {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
  }
}
@media screen and (min-width: 62em) {
  .hero-banner .accom-search {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    padding: 16px;
    border-color: #e7e7e7;
    position: absolute;
    bottom: 0;
    right: 2vw;
    width: 25vw;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  }
}
@media screen and (min-width: 87.5em) {
  .hero-banner .accom-search {
    width: 360px;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner .accom-search {
    right: 5vw;
  }
}
.hero-banner .accom-search__item--title {
  margin-left: calc(var(--default-container-gutter) * -1);
  margin-right: calc(var(--default-container-gutter) * -1);
}
@media screen and (min-width: 62em) {
  .hero-banner .accom-search__item--title {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.hero-awards {
  position: absolute;
  top: calc(var(--header-height) + 40px);
  left: var(--default-container-gutter);
  z-index: 1;
  display: none;
}
@media screen and (min-width: 75em) {
  .hero-awards {
    display: block;
  }
}
@media screen and (min-width: 87.5em) {
  .hero-awards {
    left: calc(var(--default-container-gutter) - 32px);
  }
}
.hero-awards__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
}
.hero-awards__item {
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 112px;
  width: 112px;
  margin: 0 4px;
}
@media screen and (min-width: 87.5em) {
  .hero-awards__item {
    flex-basis: 144px;
    width: 144px;
    margin: 0 8px;
  }
}
.hero-awards__item:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 1.5px;
  height: 160px;
  background-color: #000000;
}
.hero-awards__item figcaption {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hero-awards__item figcaption.focusable:active, .hero-awards__item figcaption.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.hero-awards img {
  display: block;
  width: 100%;
}

.inner-banner {
  background-color: #ffffff;
  position: relative;
}
@media screen and (min-width: 62em) {
  .inner-banner {
    min-height: var(--header-height);
  }
}
.inner-banner__img {
  background-color: #474747;
}
.inner-banner__img:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.inner-banner__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  z-index: 1;
  padding-top: var(--header-height);
  left: var(--default-container-gutter);
  right: var(--default-container-gutter);
}
.inner-banner__content a {
  color: inherit;
  opacity: 1;
}
.inner-banner__content a:hover, .inner-banner__content a:focus, .inner-banner__content a:active {
  color: inherit;
  opacity: 0.75;
}
.inner-banner__content h2, .inner-banner__content .h2,
.inner-banner__content h3, .inner-banner__content .h3 {
  color: inherit;
}
.inner-banner__content .breadcrumb {
  margin: 0;
  color: #ffffff;
}
.inner-banner__content .breadcrumb a {
  color: rgba(255, 255, 255, 0.8);
}
.inner-banner__content .breadcrumb a:hover, .inner-banner__content .breadcrumb a:focus {
  color: #ffffff;
}
.inner-banner .page-header {
  margin-bottom: 0;
}

.page-header {
  text-align: center;
  margin-bottom: var(--section-small);
}
.page-header__subtitle {
  font-size: var(--body-font-size);
}
.page-header__heading {
  display: block;
  line-height: 1;
  font-size: var(--giga-font-size);
  margin-bottom: 0;
}

.blog-post-controller .page-header {
  text-align: left;
  margin-bottom: 0;
}
.blog-post-controller .page-header__heading {
  font-size: var(--h1-font-size);
}

.image-gallery {
  margin: 0.5em;
}

.image-gallery-thumb {
  width: 100%;
  display: inline-block;
  float: left;
}

.image-gallery-caption {
  margin-top: 4px;
}

.image-gallery-popup {
  display: block;
}

.image-gallery-thumb img {
  width: 100%;
}

.image-gallery--captions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@supports (display: grid) {
  .image-gallery {
    margin: 0;
  }

  .image-gallery-thumb {
    width: auto;
    margin: 0;
  }

  .image-gallery {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
    content: none;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  .image-gallery-thumb {
    width: calc(50% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  .image-gallery-thumb {
    width: calc(33% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP medium */
@media screen and (min-width: 62em) {
  .image-gallery-thumb {
    width: calc(25% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
:root {
  --sidepadding: 8px;
  --vertpadding: 8px;
}

@media screen and (min-width: 22.5em) {
  :root {
    --sidepadding: 12px;
    --vertpadding: 10px;
  }
}
@media screen and (min-width: 35em) {
  :root {
    --sidepadding: 16px;
    --vertpadding: 12px;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --sidepadding: 24px;
    --vertpadding: 16px;
  }
}
@media screen and (min-width: 87.5em) {
  :root {
    --sidepadding: 44px;
    --vertpadding: 28px;
  }
}
.media-object__img {
  float: left;
  width: 100%;
  height: 100%;
}

.media-object__img img {
  width: 100%;
}

.media-object__content {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.media-object {
  padding: 20px;
  width: 100%;
  float: left;
}

.media-object:after,
.media-object:before {
  clear: both;
}

.media-object__details {
  font-weight: bold;
  color: #aaa9a9;
}

.media-object__date:after {
  content: " | ";
}

@media screen and (min-width: 48em) {
  .media-object {
    padding: 0;
  }

  .media-object__img {
    width: 33%;
  }

  .media-object__content {
    width: 66%;
    margin-top: 0;
    padding-left: 20px;
  }
}
:root {
  --card-gutter-width: 40px;
}

@media screen and (min-width: 48em) {
  :root {
    --card-gutter-width: 56px;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --card-gutter-width: 4%;
  }
}
.card__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--card-gutter-width) * 0.5 * -1);
  margin-left: calc(var(--card-gutter-width) * 0.5 * -1);
  justify-content: center;
}

.card__item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: calc(var(--card-gutter-width) * 0.5);
  padding-left: calc(var(--card-gutter-width) * 0.5);
  margin-bottom: var(--card-gutter-width);
}
@media screen and (min-width: 35em) {
  .card__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 48em) {
  .card__item {
    flex-basis: 33.333333%;
    max-width: 33.333333%;
  }
}
@media screen and (min-width: 62em) {
  .card__item {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.card {
  background: #ffffff;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  color: inherit;
}
.card:hover .home-service-icon.fontawesome-icon.circle-yes, .card:focus .home-service-icon.fontawesome-icon.circle-yes, .card:active .home-service-icon.fontawesome-icon.circle-yes {
  color: #00737a !important;
  border-color: #00737a !important;
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.card:hover svg path,
.card:hover svg circle,
.card:hover svg ellipse,
.card:hover svg rect,
.card:hover svg line,
.card:hover svg polygon, .card:focus svg path,
.card:focus svg circle,
.card:focus svg ellipse,
.card:focus svg rect,
.card:focus svg line,
.card:focus svg polygon, .card:active svg path,
.card:active svg circle,
.card:active svg ellipse,
.card:active svg rect,
.card:active svg line,
.card:active svg polygon {
  fill: #00737a;
}

.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 1;
}

.card__description {
  color: #1e1e1e;
  margin-bottom: 0;
}

.card__heading {
  color: #1e1e1e;
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 1em;
  bottom: 0;
  font-weight: 300;
}

.fontawesome-icon {
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.fusion-fa-align-center {
  display: block;
  clear: both;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

i.fontawesome-icon.circle-yes {
  background-color: #fff;
}

.fontawesome-icon.circle-yes {
  border-radius: 50%;
  background-clip: inherit;
  border-width: 1px;
  border-style: solid;
  background-color: #333;
  color: #fff;
  text-align: center;
  margin-top: 2px;
}

.home-service-icon.fontawesome-icon.circle-yes {
  border-color: #bcbec0;
  background-color: #ffffff;
  font-size: 52.8px;
  line-height: 105.6px;
  height: 105.6px;
  width: 105.6px;
  color: #bcbec0;
}

.services__title {
  margin-bottom: var(--card-gutter-width);
}

.tile {
  background: #ffffff;
}

.tile__inner {
  height: 100%;
}

.tile__img img {
  width: 100%;
}

.tile__content {
  padding: 20px 25px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../assets/ajax-loader.gif") center center no-repeat;
}

.slick-slider {
  margin: 0;
  padding: 0;
  min-height: 100px;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 45px;
  height: 45px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  overflow: hidden;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  content: "";
  opacity: 0.25;
  background: #000000;
  border-radius: 20px;
  transition: opacity 250ms ease-in-out, background-color 250ms ease-in-out;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  background-color: #000000;
}

.slider {
  position: relative;
  width: 100%;
}
.slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.slider__track > * {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.slider__dots {
  position: absolute;
  top: auto;
  bottom: 0;
  left: var(--default-container-gutter);
  right: auto;
  height: 24px;
  z-index: 1;
  display: flex;
}
.slider__dots a {
  padding: 12px;
  position: relative;
}
.slider__dots a span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.slider__dots a span.focusable:active, .slider__dots a span.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.slider__dots a:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 2px transparent;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 200ms ease, box-shadow 200ms ease;
}
.slider__dots a:hover:before {
  background-color: #00737a;
  box-shadow: 0 0 0 2px #ffffff;
}
.slider__dots a:focus:before {
  background-color: #00737a;
  box-shadow: 0 0 0 2px #ffffff;
}
.slider__dots a.active:before {
  background-color: #00737a;
  transition-delay: 50ms;
  box-shadow: 0 0 0 2px #ffffff;
}
.slider__arrows {
  -webkit-appearance: unset;
  position: absolute;
  background: transparent;
  line-height: normal;
  outline: none;
  padding: 0;
  border: 0;
  width: 50px;
  height: 50px;
  z-index: calc(100 - 1);
  top: 50%;
  margin-top: -25px;
  position: absolute;
  transition: border 0.3s ease-in-out, border-radius 0.2s ease-in-out, background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  border-radius: 25px;
}
.slider__arrows:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -16px;
  width: 26px;
  height: 26px;
  border: 3px solid transparent;
  border-top: 3px solid rgba(255, 255, 255, 0.75);
}
.slider__arrows:hover {
  transform: scaleX(1.2) scaleY(0.7);
}
.slider__arrows.previous {
  left: 0;
}
.slider__arrows.previous:after {
  left: 20px;
  transform: rotate(-45deg);
  border-left: 3px solid rgba(255, 255, 255, 0.75);
}
.slider__arrows.previous:focus:after, .slider__arrows.previous:active:after {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
}
.slider__arrows.next {
  right: 0;
}
.slider__arrows.next:after {
  right: 20px;
  transform: rotate(45deg);
  border-right: 3px solid rgba(255, 255, 255, 0.75);
}
.slider__arrows.next:focus:after, .slider__arrows.next:active:after {
  border-top-color: #ffffff;
  border-right-color: #ffffff;
}
.slider__arrows * {
  pointer-events: none;
}
@supports (filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.25))) {
  .slider__arrows svg {
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.25));
  }
}
.slider__arrows svg path,
.slider__arrows svg circle,
.slider__arrows svg ellipse,
.slider__arrows svg rect,
.slider__arrows svg line,
.slider__arrows svg polygon {
  stroke: #e7e7e7;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px;
  /* compensates for 2px border */
}

.slider__item img {
  object-fit: cover;
}

.splide {
  position: relative;
  width: 100%;
  margin-bottom: 60px;
}
.splide__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.splide__list::-webkit-scrollbar {
  display: none;
  width: 0;
}
.splide__slide {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.splide__pagination {
  position: absolute;
  padding: 0;
  bottom: -48px;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.splide__pagination li {
  padding: 0 6px;
  display: block;
}
.splide__pagination button {
  -webkit-appearance: none;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 2px transparent;
  height: 11px;
  width: 11px;
  border-radius: 100%;
  transition: background-color 200ms ease, box-shadow 200ms ease;
}
.splide__pagination button:hover {
  background-color: #00737a;
  box-shadow: 0 0 0 2px #ffffff;
}
.splide__pagination button:focus {
  background-color: #00737a;
  box-shadow: 0 0 0 2px #ffffff;
}
.splide__pagination button.is-active {
  background-color: #000000;
  box-shadow: 0 0 0 2px #ffffff;
}
.splide__arrow {
  -webkit-appearance: unset;
  position: absolute;
  background: transparent;
  line-height: normal;
  outline: none;
  padding: 0;
  border: 0;
  width: 50px;
  height: 50px;
  z-index: calc(100 - 1);
  top: 50%;
  margin-top: -25px;
  position: absolute;
  transition: border 0.3s ease-in-out, border-radius 0.2s ease-in-out, background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  border-radius: 25px;
}
.splide__arrow:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -16px;
  width: 26px;
  height: 26px;
  border: 3px solid transparent;
  border-top: 3px solid rgba(255, 255, 255, 0.75);
}
.splide__arrow:hover {
  transform: scaleX(1.2) scaleY(0.7);
}
.splide__arrow--prev {
  left: 0;
}
.splide__arrow--prev:after {
  left: 20px;
  transform: rotate(-45deg);
  border-left: 3px solid rgba(255, 255, 255, 0.75);
}
.splide__arrow--prev:focus:after, .splide__arrow--prev:active:after {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
}
.splide__arrow--next {
  right: 0;
}
.splide__arrow--next:after {
  right: 20px;
  transform: rotate(45deg);
  border-right: 3px solid rgba(255, 255, 255, 0.75);
}
.splide__arrow--next:focus:after, .splide__arrow--next:active:after {
  border-top-color: #ffffff;
  border-right-color: #ffffff;
}
.splide__arrow * {
  pointer-events: none;
}
.splide__arrow svg {
  display: none;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px;
  /* compensates for 2px border */
}

.slider__item img {
  object-fit: cover;
}

.cta-box {
  background-color: #f5f5f5;
  padding: 32px;
}
.cta-box__heading {
  margin-top: 0;
}
.cta-box__heading span {
  display: block;
}
.cta-box__subtitle {
  display: block;
  font-size: var(--centi-font-size);
  color: #aaa9a9;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: var(--milli-font-size);
}
@media screen and (min-width: 87.5em) {
  .cta-box__subtitle {
    font-size: var(--body-font-size);
  }
}
.cta-box__title {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: inherit;
  font-size: 150%;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 87.5em) {
  .cta-box__title {
    font-size: 200%;
    margin-bottom: 0.5em;
  }
}
.cta-box__cta a {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--border-radius);
  border-width: var(--border-width);
  border-style: solid;
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  background: #00737a;
  border-color: #00737a;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.cta-box__cta a svg {
  height: 1em;
  width: 1em;
}
.cta-box__cta a svg path,
.cta-box__cta a svg circle,
.cta-box__cta a svg ellipse,
.cta-box__cta a svg rect,
.cta-box__cta a svg line,
.cta-box__cta a svg polygon {
  fill: #ffffff;
}
.cta-box__cta a:hover, .cta-box__cta a:focus, .cta-box__cta a:active {
  background-color: #004347;
  border-color: #004347;
  color: #ffffff;
}
.cta-box__cta a:hover svg path,
.cta-box__cta a:hover svg circle,
.cta-box__cta a:hover svg ellipse,
.cta-box__cta a:hover svg rect,
.cta-box__cta a:hover svg line,
.cta-box__cta a:hover svg polygon, .cta-box__cta a:focus svg path,
.cta-box__cta a:focus svg circle,
.cta-box__cta a:focus svg ellipse,
.cta-box__cta a:focus svg rect,
.cta-box__cta a:focus svg line,
.cta-box__cta a:focus svg polygon, .cta-box__cta a:active svg path,
.cta-box__cta a:active svg circle,
.cta-box__cta a:active svg ellipse,
.cta-box__cta a:active svg rect,
.cta-box__cta a:active svg line,
.cta-box__cta a:active svg polygon {
  fill: #ffffff;
}
.cta-box__cta a:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: thin dotted #fff;
}
.cta-box__content > *:last-child {
  margin-bottom: 0;
}

.need-help-box {
  padding: 16px;
  border: 2px solid #d7d6d6;
  border-radius: 4px;
  margin-bottom: var(--body-font-size);
  overflow: hidden;
}
.need-help-box > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .need-help-box {
    padding: 24px;
  }
}
@media screen and (min-width: 48em) {
  .need-help-box {
    padding: 30px;
  }
}
.need-help-box p {
  margin-bottom: 1em;
}
.need-help-box ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.need-help-box ul li {
  margin: 0 0 6px;
  line-height: 2;
}
.need-help-box ul li::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #bcbec0;
  margin-right: 10px;
}
.need-help-box ul li.phone:before {
  content: "\f095";
}
.need-help-box ul li.email:before {
  content: "\f0e0";
}
.need-help-box ul li.enquiry:before {
  content: "\f1d8";
}
.need-help-box ul li a {
  line-height: 1.4;
  color: #1e1e1e;
}

/* ---- Slide Toggle ---- */
.slide-toggle__trigger {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  position: relative;
  white-space: normal;
  vertical-align: middle;
  outline: none;
  border: none;
  transition: all 250ms ease-in-out;
  background-color: #f5f5f5;
  background-color: transparent;
  padding: 8px 0;
  margin: 0;
  text-align: left;
  color: #636363;
}
.slide-toggle__trigger:hover, .slide-toggle__trigger:focus {
  color: #00737a;
}
.slide-toggle__trigger:hover:before, .slide-toggle__trigger:hover:after, .slide-toggle__trigger:focus:before, .slide-toggle__trigger:focus:after {
  background-color: #00737a;
}
.slide-toggle__trigger:before, .slide-toggle__trigger:after {
  content: " ";
  width: 12px;
  height: 3px;
  display: block;
  position: absolute;
  background-color: #636363;
  margin: 0 auto;
  right: 20px;
  top: 50%;
  transition: all 400ms ease;
}
.slide-toggle__trigger:before {
  transform: rotate(45deg) translateX(-5px);
}
.slide-toggle__trigger:after {
  transform: rotate(-45deg) translateX(5px);
}
.slide-toggle__trigger[aria-pressed=true] {
  color: #1e1e1e;
}
.slide-toggle__trigger[aria-pressed=true]:before, .slide-toggle__trigger[aria-pressed=true]:after {
  background-color: #1e1e1e;
  top: calc(50% - 6px);
}
.slide-toggle__trigger[aria-pressed=true]:hover, .slide-toggle__trigger[aria-pressed=true]:focus {
  color: #00737a;
}
.slide-toggle__trigger[aria-pressed=true]:hover:before, .slide-toggle__trigger[aria-pressed=true]:hover:after, .slide-toggle__trigger[aria-pressed=true]:focus:before, .slide-toggle__trigger[aria-pressed=true]:focus:after {
  background-color: #00737a;
}
.slide-toggle__trigger[aria-pressed=true]:before {
  transform: rotate(-45deg) translateX(-5px);
}
.slide-toggle__trigger[aria-pressed=true]:after {
  transform: rotate(45deg) translateX(5px);
}

.archive-links__list[aria-expanded=false] {
  display: none;
}

.slide-toggle__trigger {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

.archive-links__list-depth0 .slide-toggle__trigger {
  margin-top: 0.5em;
}
.archive-links__list-depth0 .slide-toggle__trigger:first-child {
  margin-top: 0;
}

.video-banner {
  position: relative;
}

.video-banner__video {
  display: block;
  background-color: #474747;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 56.25vw;
}
@media screen and (min-width: 120em) {
  .video-banner__video {
    max-width: 2400px;
    max-height: calc(2400px * 0.5625);
  }
}
.video-banner__video:before {
  content: "";
  display: block;
}
.video-banner__video iframe {
  inset: 0 0 0 !important;
  width: 100vw !important;
  height: 56.25vw !important;
}
@media screen and (min-width: 120em) {
  .video-banner__video iframe {
    max-width: 2400px !important;
    max-height: calc(2400px * 0.5625) !important;
  }
}

.testimonial-item {
  display: block;
}
.testimonial-item__attribution {
  line-height: normal;
  position: relative;
  background-color: #000000;
  color: #ffffff;
  padding: 30px;
  margin-bottom: 0;
}
.testimonial-item__attribution:after {
  position: absolute;
  left: 35px;
  top: 0;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: medium solid transparent;
  border-top-color: #f6f6f6;
  border-width: 10px;
}
.testimonial-item__attribution__name {
  display: block;
  font-size: 18px;
  color: #bcbec0;
  font-weight: 400;
}
.testimonial-item__attribution__job:after {
  content: " @ ";
}
.testimonial-faqs .row {
  --gutter-width: 4%;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

.testimonial__slider .splide__arrows {
  display: none;
}
.testimonial__slider .splide__pagination button {
  background-color: transparent;
  border: 1px solid #747474;
  box-shadow: 0 0 0 2px transparent;
  height: 12px;
  width: 12px;
  transition: background-color 200ms ease, border 200ms ease, box-shadow 200ms ease;
}
.testimonial__slider .splide__pagination button:hover {
  background-color: #747474;
  box-shadow: 0 0 0 2px transparent;
}
.testimonial__slider .splide__pagination button:focus {
  background-color: #00737a;
  box-shadow: 0 0 0 2px transparent;
}
.testimonial__slider .splide__pagination button.is-active {
  background-color: #747474;
  box-shadow: 0 0 0 2px transparent;
}

.review-item {
  display: block;
}
.review-item__speech-bubble {
  position: relative;
  background-color: #ffffff;
  background-image: url(../assets/icon_tripadvisor.svg);
  background-position: right 8px bottom 8px;
  background-repeat: no-repeat;
  padding: 40px 40px 48px 56px;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 0;
}
@media screen and (min-width: 48em) {
  .review-item__speech-bubble {
    flex: 1 1 auto;
  }
}
.review-item__speech-bubble:before {
  content: "“";
  display: block;
  position: absolute;
  top: 40px;
  left: 60px;
  bottom: auto;
  right: auto;
  width: 24.6094px;
  height: 96px;
  color: rgb(187, 187, 187);
  perspective-origin: 12.2969px 48px;
  transform-origin: 12.2969px 48px;
  font: normal normal normal normal 60px/96px Georgia, serif;
  margin: -25px 0 0 -40px;
}
.review-item__speech-bubble:after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  bottom: -30px;
  left: 56px;
  height: 30px;
  width: 30px;
  perspective-origin: 15px 15px;
  transform-origin: 15px 15px;
  filter: drop-shadow(rgba(0, 0, 0, 0.0980392) 2px 2px 1px);
  border-top: 30px solid #ffffff;
  border-right: 30px solid transparent;
  border-bottom: none;
  border-left: none;
}
.review-item__speech-bubble blockquote {
  all: unset;
}
.review-item__speech-bubble blockquote:after {
  content: none;
}
.review-item__score {
  display: flex;
  margin-bottom: 0.5em;
}
.mfp-content .review-item__score {
  margin-bottom: 1em;
}
.review-item__score .score {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  border: 2px solid #d7d6d6;
  border-radius: 100%;
  background-color: #fff;
  margin-right: 1px;
}
.review-item__score .score:after {
  content: "";
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 100%;
  background-color: #d7d6d6;
}
.review-item__score .score.filled {
  border-color: #34E0A1;
}
.review-item__score .score.filled:after {
  background-color: #34E0A1;
}
.review-item__attribution {
  line-height: normal;
  color: rgb(85, 85, 85);
  display: block;
  text-transform: uppercase;
  padding: 8px 0 0 86px;
  margin-bottom: 0;
  font-size: var(--centi-font-size);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}
.mfp-content .review-item__attribution {
  margin-top: 1em;
  padding-left: 0;
}
.review-item__attribution__name {
  display: block;
  font-weight: 400;
}
.reviews-slider__slider {
  position: relative;
  margin: 0 -15px;
}
.reviews-slider__slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.reviews-slider__slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.reviews-slider__slider__track > * {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.reviews-slider__slider .slider__dots {
  position: static;
  justify-content: center;
  margin-top: 24px;
}
.reviews-slider__slider .slider__dots a:before {
  background-color: transparent;
  border: 1px solid #bcbec0;
  box-shadow: none;
}
.reviews-slider__slider .slider__dots a.active:before {
  background-color: #bcbec0;
}
.reviews-slider__slider .slider__dots a:hover:before {
  background-color: #000000;
  border-color: #000000;
}
.reviews-slider__slider .slider__dots a:focus:before {
  background-color: #000000;
  border-color: #000000;
}
.reviews-slider__slider .slider__arrows {
  vertical-align: middle;
  display: inline-block;
  border: none;
  background: #bcbec0;
  color: #000000;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  height: auto;
  width: var(--default-container-gutter);
  border-radius: 0;
  margin-top: 0;
}
@media screen and (min-width: 87.5em) {
  .reviews-slider__slider .slider__arrows {
    border: 1px solid #bcbec0;
    background-color: #ffffff;
    color: #ffffff;
    border-radius: 32px;
    height: 32px;
    width: 32px;
    top: 50%;
    bottom: auto;
    margin-top: -16px;
  }
}
.reviews-slider__slider .slider__arrows:after {
  content: none;
}
.reviews-slider__slider .slider__arrows.previous {
  right: calc(100% - 15px);
  left: auto;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 100%);
}
@media screen and (min-width: 87.5em) {
  .reviews-slider__slider .slider__arrows.previous {
    right: calc(100% + 14px);
    background: #bcbec0;
  }
}
.reviews-slider__slider .slider__arrows.next {
  left: calc(100% - 15px);
  right: auto;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
}
@media screen and (min-width: 87.5em) {
  .reviews-slider__slider .slider__arrows.next {
    left: calc(100% + 14px);
    background: #bcbec0;
  }
}
.reviews-slider__slider .slider__arrows:hover {
  transform: none;
}
.reviews-slider__slider__item {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 0 15px;
}
@media screen and (min-width: 48em) {
  .reviews-slider__slider__item {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 62em) {
  .reviews-slider__slider__item {
    flex: 0 0 33.33333%;
  }
}

.explore-item {
  display: block;
}
.explore-item__link-wrap {
  color: inherit;
  overflow: hidden;
  border: none;
}
.explore-item__link-wrap:hover .explore-item__picture img, .explore-item__link-wrap:focus .explore-item__picture img, .explore-item__link-wrap:active .explore-item__picture img {
  transform: scale(1.2);
}
.explore-item__link-wrap:focus .explore-item__picture:after, .explore-item__link-wrap:active .explore-item__picture:after {
  right: 0;
}
.explore-item__picture {
  display: block;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background: #474747;
  background-image: url(../assets/logo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}
@supports (aspect-ratio: 3/2) {
  .explore-item__picture {
    aspect-ratio: 3/2;
  }
}
.explore-item__picture:after {
  content: "\e619";
  text-indent: 0;
  line-height: normal;
  text-shadow: none;
  font-family: icomoon;
  font-size: 15px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 0;
  right: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: block;
  opacity: 0.9;
  vertical-align: middle;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: right 250ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.explore-item__picture:hover:after, .explore-item__picture:focus:after, .explore-item__picture:active:after {
  right: 0;
}
.explore-item__picture img {
  transition: transform 250ms ease;
}
@supports (aspect-ratio: 3/2) {
  .explore-item__picture img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.explore-item h3 {
  font-weight: 500;
  color: #1e1e1e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 1em;
}
.explore-item__content {
  background-color: #FFF;
  padding: 30px 0 0 0;
  position: relative;
  z-index: 10;
  color: #636363;
}
.explore-item__copy {
  margin-top: 0;
  margin-bottom: 20px;
}
.explore-item__meta {
  list-style: none;
  margin: 0 0 13px;
  padding: 0;
  overflow: hidden;
  line-height: 14px;
  color: #333;
  font-size: 12px;
}
.explore-item__meta i {
  margin-right: 5px;
  color: #bcbec0;
}
.explore-item__meta span {
  display: inline-block;
  margin-right: 20px;
  color: #1e1e1e;
}

.explore-item.landscape {
  margin-bottom: 20px;
}
.explore-item.landscape .explore-item__link-wrap {
  display: flex;
  align-items: center;
}
.explore-item.landscape .explore-item__picture {
  flex: 0 0 33.33333333%;
}
@media screen and (min-width: 62em) {
  .explore-item.landscape .explore-item__picture {
    flex-basis: 41.66666667%;
  }
}
@media screen and (min-width: 75em) {
  .explore-item.landscape .explore-item__picture {
    flex-basis: 33.33333333%;
  }
}
.explore-item.landscape .explore-item__content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 15px;
}
.explore-item.landscape .explore-item__content .explore-item__meta {
  margin-bottom: 0;
  order: 1;
}
.explore-item.landscape .explore-item__content .explore-item__title {
  font-size: 18px;
  font-weight: 700;
  color: #1e1e1e;
  margin: 0.2em;
  white-space: normal;
  order: 0;
}

#room-template .explore-item.landscape .explore-item__title {
  font-size: 18px;
}

.explore-gallery {
  --gutter-width: 3rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
.explore-gallery__item {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 0 var(--half-gutter-width);
  margin-bottom: var(--gutter-width);
}
@media screen and (min-width: 35em) {
  .explore-gallery__item {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 62em) {
  .explore-gallery__item {
    flex: 0 0 33.33333%;
  }
}

.explore-slider__title {
  text-align: center;
}
.explore-slider__slider {
  position: relative;
  margin: 0 -15px;
}
.explore-slider__slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.explore-slider__slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.explore-slider__slider__track > * {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.explore-slider__slider .slider__dots {
  display: none;
  position: static;
  justify-content: center;
  margin-top: 24px;
}
.explore-slider__slider .slider__dots a:before {
  background-color: rgba(0, 0, 0, 0.5);
}
.explore-slider__slider .slider__dots a.active:before {
  background-color: #00737a;
}
.explore-slider__slider .slider__dots a:hover:before {
  background-color: #000000;
}
.explore-slider__slider .slider__dots a:focus:before {
  background-color: #000000;
}
.explore-slider__slider .slider__arrows {
  vertical-align: middle;
  display: inline-block;
  border: none;
  background: #bcbec0;
  color: #000000;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  height: auto;
  width: var(--default-container-gutter);
  border-radius: 0;
  margin-top: 0;
}
@media screen and (min-width: 87.5em) {
  .explore-slider__slider .slider__arrows {
    border: 1px solid #bcbec0;
    background-color: #ffffff;
    color: #ffffff;
    border-radius: 32px;
    height: 32px;
    width: 32px;
    top: 50%;
    bottom: auto;
    margin-top: -16px;
  }
}
.explore-slider__slider .slider__arrows:after {
  content: none;
}
.explore-slider__slider .slider__arrows.previous {
  right: calc(100% - 15px);
  left: auto;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 100%);
}
@media screen and (min-width: 87.5em) {
  .explore-slider__slider .slider__arrows.previous {
    right: calc(100% + 14px);
    background: #bcbec0;
  }
}
.explore-slider__slider .slider__arrows.next {
  left: calc(100% - 15px);
  right: auto;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
}
@media screen and (min-width: 87.5em) {
  .explore-slider__slider .slider__arrows.next {
    left: calc(100% + 14px);
    background: #bcbec0;
  }
}
.explore-slider__slider .slider__arrows:hover {
  transform: none;
}
.explore-slider__slider__item {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 0 15px;
}
@media screen and (min-width: 48em) {
  .explore-slider__slider__item {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 62em) {
  .explore-slider__slider__item {
    flex: 0 0 33.33333%;
  }
}
.explore-slider__cta {
  padding-top: 24px;
}

.content-block {
  display: grid;
  gap: 40px;
}
@media screen and (min-width: 62em) {
  .content-block {
    grid-template-columns: 1fr;
    gap: 4%;
  }
}
@media screen and (min-width: 62em) {
  .content-block.half-media-right {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.half-media-left {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.sidebar-media-right {
    grid-template-columns: 3fr 2fr;
  }
}
@media screen and (min-width: 62em) {
  .content-block.sidebar-media-left {
    grid-template-columns: 2fr 3fr;
  }
}

.image-gallery__figure {
  display: block;
  border-radius: 6px;
  overflow: hidden;
  margin: 0;
}

.image-gallery__slider .splide__slide img {
  user-select: none;
  transition: opacity 1s, transform 1s;
}
.image-gallery__slider .splide__slide img:hover, .image-gallery__slider .splide__slide img:focus, .image-gallery__slider .splide__slide img:active {
  opacity: 0.9;
  transform: scale3d(1.1, 1.1, 1);
}
.image-gallery__slider .splide__slide.active img {
  animation: fadeIn 350ms ease-in;
  animation-fill-mode: forwards;
}
.image-gallery__slider.columns-2 .splide__slide {
  flex: 0 0 50%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-3 .splide__slide {
  flex: 0 0 33.333%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-4 .splide__slide {
  flex: 0 0 25%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-5 .splide__slide {
  flex: 0 0 20%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.image-gallery__slider.columns-6 .splide__slide {
  flex: 0 0 16.666%;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.image-gallery__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.image-gallery__grid.columns-2, .image-gallery__grid.columns-4 {
  grid-template-columns: repeat(2, 1fr);
}
.image-gallery__grid.columns-3, .image-gallery__grid.columns-5, .image-gallery__grid.columns-6 {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (min-width: 48em) {
  .image-gallery__grid.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .image-gallery__grid.columns-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .image-gallery__grid.columns-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.image-gallery__grid figure,
.image-gallery__grid img {
  width: 100%;
}
.image-gallery__grid .image-gallery__figure {
  position: relative;
}
.image-gallery__grid .image-gallery__figure:hover figcaption, .image-gallery__grid .image-gallery__figure:focus figcaption, .image-gallery__grid .image-gallery__figure:active figcaption {
  opacity: 1;
}
.image-gallery__grid .image-gallery__figure figcaption {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  left: 10px;
  padding: 10px;
  z-index: 2;
  opacity: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  margin: 0;
  border-radius: 6px;
  transition: 0.4s all ease;
  font-weight: 700;
  line-height: 1.3;
}
@media screen and (min-width: 35em) {
  .image-gallery__grid .image-gallery__figure figcaption {
    font-size: 18px;
    top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    padding: 16.6666%;
  }
}
@media screen and (min-width: 48em) {
  .image-gallery__grid .image-gallery__figure figcaption {
    font-size: 24px;
  }
}

.entry-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}
.entry-list.columns-1 {
  gap: 0;
}
.entry-list.columns-2 {
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 48em) {
  .entry-list.columns-2 {
    column-gap: 4%;
  }
}
.entry-list.columns-3, .entry-list.columns-5, .entry-list.columns-6 {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.entry-list.columns-4 {
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 48em) {
  .entry-list.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .entry-list.columns-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .entry-list.columns-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.entry-list .card {
  padding: 50px 35px 35px;
}
.entry-list .card .button {
  order: 2;
  align-self: center;
  margin-top: 1em;
}

.stats-section__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;
  border: 1px solid #8d8d8d;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
  overflow: hidden;
}
.stats-section__item {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
  border-right: 1px solid #8d8d8d;
  font-size: 13px;
  min-width: 100px;
  padding: 20px 10px;
}
.stats-section__item:last-child {
  border: none;
}
.stats-section__item__value {
  display: block;
  color: #bcbec0;
  font-size: 28px;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  .stats-section__item__value {
    font-size: 24px;
  }
}

.embedded-map {
  position: relative;
  height: 44vw;
}
@media screen and (min-width: 48em) {
  .embedded-map {
    height: 46vw;
  }
}
@media screen and (min-width: 75em) {
  .embedded-map {
    height: 570px;
  }
}
@supports (mix-blend-mode: hue) {
  .embedded-map:after {
    background-color: #00737a;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    opacity: 0.2;
    mix-blend-mode: hue;
    transition: mix-blend-mode 250ms ease, filter 250ms ease, opacity 250ms ease;
    z-index: 1;
  }
}
@supports (mix-blend-mode: hue) {
  .embedded-map:hover:after, .embedded-map:focus:after {
    opacity: 0;
  }
}
@supports (filter: grayscale(0.2)) {
  .embedded-map:hover iframe, .embedded-map:focus iframe {
    filter: grayscale(0.2);
  }
}
@supports (mix-blend-mode: hue) {
  .embedded-map:focus-within:after {
    opacity: 0;
  }
}
@supports (filter: grayscale(0.2)) {
  .embedded-map:focus-within iframe {
    filter: grayscale(0.2);
  }
}
.embedded-map iframe {
  display: block;
  height: 100%;
  width: 100%;
  transition: filter 250ms ease, mix-blend-mode 250ms ease, opacity 250ms ease;
}
@supports (filter: grayscale(0.75)) {
  .embedded-map iframe {
    filter: grayscale(0.75);
  }
}

/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */
/* ---- Global ---- */
:root {
  --header-height: 118px;
  --header-height-shrink: 64px;
}

@media screen and (min-width: 62em) {
  :root {
    --header-height: 118px;
  }
}
#header {
  position: relative;
  z-index: 100;
  display: none;
}
@media screen and (min-width: 62em) {
  #header {
    display: block;
  }
}
@media screen and (min-width: 150em) {
  #header {
    max-width: 2400px;
    margin: 0 auto;
  }
}

.header {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--snav_height);
  height: var(--header-height);
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
  transition: height 350ms ease;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fusion-logo {
  --logo_margin-top: 20px;
  --logo_margin-bottom: 20px;
  --logo_margin-left: 0px;
  --logo_margin-right: 0px;
}

#side-header .fusion-logo,
.fusion-header .fusion-logo {
  margin: var(--logo_margin-top) var(--logo_margin-right) var(--logo_margin-bottom) var(--logo_margin-left);
}

.fusion-logo img,
#side-header .fusion-logo,
.fusion-header .fusion-logo {
  max-height: 79px !important;
  width: 100% !important;
  max-width: 171px !important;
}

.header__logo {
  display: block;
  height: var(--header-height);
  transition: height 350ms ease;
}
@media screen and (max-width: 61.9375em) {
  .header__logo {
    height: calc(var(--header-height) - 40px);
  }
}
@media screen and (min-width: 62em) {
  .header__logo {
    height: var(--header-height);
  }
}
.header__logo svg {
  height: 100%;
}

.header-sticky .header {
  position: fixed;
  top: 0;
}
@media screen and (min-width: 150em) {
  .header-sticky .header {
    left: calc(50vw - (2400px/2));
    right: calc(50vw - (2400px/2));
  }
}

.header-shrink .header__logo {
  height: calc(var(--header-height-shrink) - 16px);
  top: 0;
}

:root {
  --header_top_bg_color: #00737a;
  --snav_color: #ffffff;
  --snav_height: 44px;
}

.fusion-secondary-header {
  background-color: var(--header_top_bg_color);
  color: var(--snav_color);
  font-size: 12px;
  border: none;
}
@media screen and (max-width: 991px) {
  .fusion-secondary-header {
    display: none !important;
  }
}
.fusion-secondary-header a {
  color: #fff;
}
.fusion-secondary-header a:hover, .fusion-secondary-header a:focus, .fusion-secondary-header a:active {
  color: #fff;
}
.fusion-secondary-header .container {
  font-size: 14px;
}
.fusion-secondary-header .fusion-mobile-nav-holder {
  width: 80%;
  margin: 0 auto;
}

.fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-icon-only-link .menu-title,
.fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-icon-only-link .menu-title {
  display: none;
}

html.avada-has-site-width-percent body .fusion-secondary-header,
html.avada-has-site-width-percent body.avada-responsive .fusion-secondary-header {
  padding-left: 0;
  padding-right: 0;
}

html.avada-has-site-width-percent.avada-has-site-width-100-percent .fusion-body .fusion-secondary-header {
  padding-left: 30px;
  padding-right: 30px;
}

html.avada-has-site-width-percent .avada-responsive.layout-boxed-mode .fusion-secondary-header .fusion-row {
  max-width: none;
  padding-left: 10px;
  padding-right: 10px;
}

.fusion-mobile-menu-design-flyout .fusion-secondary-header,
.fusion-mobile-menu-design-modern .fusion-secondary-header {
  padding: 0;
}

.fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-row,
.fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-row {
  padding-left: 0;
  padding-right: 0;
}

.fusion-body .fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-alignleft,
.fusion-body .fusion-mobile-menu-design-flyout .fusion-secondary-header .fusion-alignright,
.fusion-body .fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-alignleft,
.fusion-body .fusion-mobile-menu-design-modern .fusion-secondary-header .fusion-alignright {
  text-align: center;
}

.fusion-mobile-menu-design-modern .fusion-secondary-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fusion-secondary-header {
  background-color: #00737a;
}

.fusion-secondary-header .fusion-row {
  display: block;
}

.fusion-secondary-header .fusion-alignleft {
  margin-right: 0;
}

.fusion-secondary-header .fusion-alignright {
  margin-left: 0;
}

body.fusion-body .fusion-secondary-header .fusion-alignright > * {
  float: none;
}

body.fusion-body .fusion-secondary-header .fusion-alignright .fusion-social-links-header .boxed-icons {
  margin-bottom: 5px;
}

.fusion-mobile-menu-design-classic .fusion-secondary-header {
  padding: 10px;
}

.fusion-mobile-menu-design-classic .fusion-secondary-header .fusion-mobile-nav-holder {
  margin-top: 0;
}

.fusion-secondary-header .fusion-row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fusion-mobile-menu-design-flyout .fusion-social-links-header,
.fusion-mobile-menu-design-modern .fusion-social-links-header {
  max-width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 8px;
}
.fusion-mobile-menu-design-flyout .fusion-social-links-header a,
.fusion-mobile-menu-design-modern .fusion-social-links-header a {
  margin-right: 20px;
  margin-bottom: 5px;
}

/* ---------------------------------
Social links
----------------------------------- */
.fusion-social-links-header {
  margin-top: 10px;
  margin: 0;
  text-align: right;
}
.fusion-social-links-header .fusion-social-networks-wrapper {
  margin: 0 !important;
}
.fusion-social-links-header .fusion-social-networks a {
  margin: 0;
  display: inline-block;
  line-height: var(--snav_height);
  vertical-align: middle;
  padding: 0 12px;
  font-size: 16px;
}
.fusion-social-links-header .fusion-social-networks a:hover {
  color: #FFFFFF !important;
  opacity: 1 !important;
  background: #bcbec0;
}
.fusion-social-links-header .fusion-social-networks a.custom img {
  max-height: 16px;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons .fusion-social-network-icon {
  color: #fffcfc;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons .fusion-social-network-icon:not(.custom) {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
  border-radius: 4px;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons .boxed-icons a {
  padding: 8px;
  width: 34px;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons .boxed-icons a.custom {
  top: -8px;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons .boxed-icons a.custom img {
  max-height: 34px;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons .boxed-icons a img {
  max-height: 34px;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons a.fusion-social-network-icon.fusion-custom.fusion-icon-custom {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.fusion-social-links-header .fusion-social-networks.boxed-icons a.fusion-social-network-icon.fusion-custom.fusion-icon-custom img {
  position: relative;
}
.fusion-social-links-header .fusion-social-networks .fusion-social-network-icon {
  color: #fffcfc;
}

.fusion-mobile-menu-design-flyout .fusion-social-links-header,
.fusion-mobile-menu-design-modern .fusion-social-links-header {
  max-width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 8px;
}

.fusion-mobile-menu-design-flyout .fusion-social-links-header a,
.fusion-mobile-menu-design-modern .fusion-social-links-header a {
  margin-right: 20px;
  margin-bottom: 5px;
}

.fusion-header-wrapper .fusion-mobile-menu-design-classic .fusion-social-links-header {
  max-width: 100%;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 5px;
}

body.fusion-body .fusion-secondary-header .fusion-alignright .fusion-social-links-header .boxed-icons {
  margin-bottom: 5px;
}

.fusion-header-wrapper .fusion-mobile-menu-design-classic .fusion-social-links-header {
  max-width: 100%;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 5px;
}

#side-header .fusion-social-links-header {
  margin: 0;
}
#side-header .fusion-social-links-header .fusion-social-networks a {
  margin-bottom: 20px;
}
#side-header .fusion-social-networks.boxed-icons .fusion-social-network-icon {
  margin-bottom: 10px;
}

/* ---------------------------------
Contact Info
----------------------------------- */
.fusion-contact-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.fusion-contact-info ul li {
  display: inline-block;
  padding: 0 20px;
}

.fusion-contact-info ul li a {
  color: #FFF;
}

.fusion-contact-info ul li i {
  color: #FFF;
  margin: 0 8px 0 0;
}

.fusion-contact-info ul li:first-child {
  padding-left: 0;
}

.fusion-contact-info ul li:last-child {
  padding-right: 0;
  border-right: none;
}

:root {
  --footerw_bg_image: ;
  --footerw_bg_full-size: initial;
  --footerw_bg_full-position: center center;
  --footerw_bg_repeat: no-repeat;
  --footerw_bg_pos: center center;
  --footer_bg_color: #1e1e1e;
  --footer_border_size: 12px;
  --footer_border_color: #e9eaee;
  --footer_link_color: $color-grey-04;
  --footer_link_color_hover: #ffffff;
}

.footer {
  background: var(--footer_bg_color);
  color: #8d8d8d;
}
.footer a {
  color: inherit;
}
.footer a:hover, .footer a:focus, .footer a:active {
  color: #ffffff;
}
.footer h3 {
  color: #bcbec0;
}

@media screen and (min-width: 48em) {
  .footer .row {
    --gutter-width: 30px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

.footer__ankle .row .col-xs-12 {
  margin-bottom: 40px;
}
.footer__ankle .row .col-xs-12:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .footer__ankle .row .col-xs-12 {
    margin-bottom: 0;
  }
  .footer__ankle .row .col-xs-12:first-child {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 62em) {
  .footer__ankle .row .col-xs-12:first-child {
    margin-bottom: 0;
  }
}

/* Column one - logo & note */
.footer__logo {
  display: block;
  margin-bottom: 1em;
}
.footer__logo .logo__img {
  max-width: 250px;
}

.footer__note {
  color: #8d8d8d;
}
.footer__note > p:last-child {
  margin-bottom: 0;
}
.footer__note .button {
  border-color: #bcbec0;
  background: #bcbec0;
  color: #1e1e1e;
  font-size: 13px;
}
.footer__note .button:hover, .footer__note .button:focus, .footer__note .button:active {
  background: #e3e4e5;
  border-color: #e3e4e5;
  color: #1e1e1e;
}

/* Column two - link */
@media screen and (min-width: 62em) {
  .footer__links {
    padding-left: 60px;
  }
}

.footer__linklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Column three - news */
.footer__news {
  display: block;
  text-decoration: none;
}
.footer__news:focus {
  outline: thin dotted #fff;
}
.footer__news__title {
  font-size: var(--body-font-size);
  margin-bottom: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
}

/* Column four - contact details */
.footer__contact {
  color: #8d8d8d;
}
.footer__contact__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer__contact__item {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.4;
  margin: 0 0 20px;
  position: relative;
}
.footer__contact__item:before {
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #bcbec0;
  margin-right: 10px;
  margin-top: 0.2em;
}
.footer__contact__item.address::before {
  content: "\f3c5";
}
.footer__contact__item.phone {
  margin-bottom: 8px;
}
.footer__contact__item.phone::before {
  content: "\f095";
}
.footer__contact__item.alternative-phone::before {
  content: " ਀";
}
.footer__contact__item.email {
  margin-bottom: 8px;
}
.footer__contact__item.email::before {
  content: "\f0e0";
}
.footer__contact__item.enquiry::before {
  content: " ਀";
}
.footer__contact__item a {
  display: block;
}
.footer__contact__item__title {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.footer__contact__item__title.focusable:active, .footer__contact__item__title.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.fusion-footer-copyright-area {
  --copyright_padding-top: 0;
  --copyright_padding-bottom: 0;
  --copyright_bg_color: #1e1e1e;
  --copyright_border_size: 1px;
  --copyright_border_color: #1e1e1e;
  --copyright_link_color_hover: #bcbec0;
}

.footer__toes {
  border-top: 1px solid #8d8d8d;
}

.footer__website-attribution {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: var(--milli-font-size);
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 0;
}
.footer__website-attribution__item {
  display: block;
  flex: 0 0 auto;
  padding-left: 4px;
  padding-right: 4px;
  transition: filter 250ms ease;
}
@supports (filter: grayscale(1)) {
  .footer__website-attribution__item {
    filter: grayscale(1);
  }
  .footer__website-attribution__item:hover, .footer__website-attribution__item:focus, .footer__website-attribution__item:active {
    filter: grayscale(0);
  }
}
.footer__website-attribution__img {
  width: 20px;
  height: auto;
}
.footer__website-attribution__note {
  display: block;
  flex: 0 0 100%;
  margin-bottom: 1em;
}

.footer__text {
  padding-top: 16px;
  padding-bottom: 16px;
}

.footer__text p {
  margin: 0;
  display: inline;
}

.footer__text p:before {
  content: " | ";
}

.footer__text p:first-child:before {
  content: none;
}

/* BP small */
@media screen and (min-width: 48em) {
  .section--pre-footer .block-list {
    -webkit-Column-count: 2;
    -moz-Column-count: 2;
    Column-count: 2;
    -webkit-Column-gap: 40px;
    -moz-Column-gap: 40px;
    Column-gap: 40px;
  }

  .section--footer {
    text-align: auto;
  }

  .footer__text {
    padding: 0;
  }
}
.sidebar-widget,
#room-template .sidebar-widget {
  margin-bottom: 40px;
}
.sidebar-widget__title,
.sidebar-widget h4,
#room-template .sidebar-widget__title,
#room-template .sidebar-widget h4 {
  margin: 0 0 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.sidebar-document-gallery {
  --gutter-width: 1rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
  margin-bottom: 24px;
}
.sidebar-document-gallery__item {
  margin-bottom: var(--gutter-width);
}
.sidebar-document-gallery .button {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

.sidebar-contact h3 {
  font-weight: 300;
  font-size: 18px;
  color: #bcbec0;
  margin-top: 0;
  margin-bottom: 5px;
}
.sidebar-contact p,
.sidebar-contact p > a {
  margin: 0;
  color: #1e1e1e;
}
.sidebar-contact a:hover, .sidebar-contact a:focus, .sidebar-contact a:active {
  text-decoration: underline;
}
.sidebar-contact .fusion-text {
  margin: 0 0 40px;
  position: relative;
  padding-left: 65px;
}
.sidebar-contact .fusion-text:before {
  position: absolute;
  top: 5px;
  left: 0;
  content: "\f3c5";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #1e1e1e;
  margin-right: 10px;
}
.sidebar-contact .fusion-text.contact-address:before {
  content: "\f3c5";
}
.sidebar-contact .fusion-text.contact-phone:before {
  content: "\f095";
}
.sidebar-contact .fusion-text.contact-email:before {
  content: "\f0e0";
  top: 0;
}
.sidebar-contact .fusion-text:nth-child(4) {
  padding-left: 0;
}
.sidebar-contact .fusion-text:nth-child(4):before {
  display: none;
}
.sidebar-contact .fusion-text:nth-child(4) h4 {
  margin: 0;
  color: #1e1e1e;
  display: inline-block;
  vertical-align: middle;
}

.sidebar-widget.sidebar-social-list {
  display: flex;
  align-items: center;
}
.sidebar-widget.sidebar-social-list h3 {
  margin: 0;
  color: #1e1e1e;
  display: inline-block;
  font-size: 18px;
}
.sidebar-widget.sidebar-social-list .social-list {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 1em;
}
.sidebar-widget.sidebar-social-list .social-list__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-widget.sidebar-social-list .social-list__item a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-widget.sidebar-social-list .social-list__item svg {
  height: 20px;
  width: 20px;
}
.sidebar-widget.sidebar-social-list .social-list__item svg path,
.sidebar-widget.sidebar-social-list .social-list__item svg circle,
.sidebar-widget.sidebar-social-list .social-list__item svg ellipse,
.sidebar-widget.sidebar-social-list .social-list__item svg rect,
.sidebar-widget.sidebar-social-list .social-list__item svg line,
.sidebar-widget.sidebar-social-list .social-list__item svg polygon {
  fill: #474747;
}

.tile-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tile-list:before, .tile-list:after {
  content: " ";
  display: table;
}
.tile-list:after {
  clear: both;
}
.tile-list__item {
  margin-bottom: 25px;
}

@media screen and (min-width: 48em) {
  /*  768px */
  .tile-list {
    margin: 0 -30px;
  }
  .tile-list__item {
    width: 33.333%;
    padding: 15px 30px;
    float: left;
  }
}
/* ---- top-heavy-list ---- */
/* To display a list of products nicely */
.top-heavy-list {
  width: 100%;
  list-style: none;
  padding: 20px 0 30px;
  margin: 0 -10px;
}
.top-heavy-list:before, .top-heavy-list:after {
  content: " ";
  display: table;
}
.top-heavy-list:after {
  clear: both;
}
.top-heavy-list > .top-heavy-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.top-heavy-list + .top-heavy-list {
  padding-top: 0;
}
.top-heavy-list__item {
  padding: 10px;
}

@media screen and (min-width: 35em) {
  .top-heavy-list__item {
    padding: 10px;
    width: 50%;
    float: left;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 62em) {
  .top-heavy-list__item {
    width: 33.3333%;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 87.5em) {
  .top-heavy-list__item {
    width: 25%;
  }
  .mainbar--narrow .top-heavy-list__item {
    width: 33.33333333%;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(4n+1) {
    clear: left;
  }
}
/* Flex code */
@supports (display: flex) {
  @media screen and (min-width: 35em) {
    .top-heavy-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px) .top-heavy-list __item;
      width-margin-right: auto;
      width-margin-left: auto;
      width-padding: 0 10px 20px;
      width-flex-basis: 50%;
      width-min-width: 50%;
      width-width: auto !important;
    }
    .top-heavy-list--five .top-heavy-list__item, .top-heavy-list__item:nth-child(-n+3) {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 61.9375em) {
    .top-heavy-list--three .top-heavy-list__item:first-child, .top-heavy-list--five .top-heavy-list__item:first-child, .top-heavy-list--seven .top-heavy-list__item:first-child, .top-heavy-list--odd .top-heavy-list__item:first-child {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 62em) {
    .top-heavy-list {
      margin-left: -2%;
      margin-right: -2%;
      /*  grid with sidebar, stays only 3 columns  */
    }
    .top-heavy-list .top-heavy-list__item {
      padding: 2%;
      flex-basis: 25%;
      min-width: 25%;
    }
    .top-heavy-list--odd > .top-heavy-list__item, .mainbar--narrow .top-heavy-list > .top-heavy-list__item, .top-heavy-list--six > .top-heavy-list__item, .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+3) {
      flex-basis: 33.33333333%;
      min-width: 33.33333333%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(n+3) {
      flex-basis: 20%;
    }
    .mainbar--narrow .top-heavy-list--four > .top-heavy-list__item:nth-child(-n+4), .mainbar--narrow .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+4) {
      flex-basis: 50%;
    }
    .mainbar--narrow .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2), .mainbar--narrow .top-heavy-list--even > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
  }
}
.media-object__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-object__list:before, .media-object__list:after {
  content: " ";
  display: table;
}
.media-object__list:after {
  clear: both;
}

.media-object__item {
  padding: 10px 0;
}

.media-object__list:after {
  clear: both;
}

.sitemap {
  text-align: left;
}
@media screen and (min-width: 48em) {
  .sitemap {
    columns: 2;
  }
}
@media screen and (min-width: 62em) {
  .sitemap {
    columns: 3;
  }
}
.sitemap__section {
  break-inside: avoid;
}
.sitemap__section:first-child .sitemap__list-title {
  margin-top: 0;
}
.sitemap__list-title {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: inherit;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.sitemap ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sitemap ul > li:first-child > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.sitemap ul > li:last-child > a {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.sitemap .sitemap-item button {
  display: block;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-align: left;
  padding-left: 1.25em;
}
.sitemap .sitemap-item a,
.sitemap .sitemap-item button {
  text-decoration: none;
  display: block;
  line-height: 2;
  background-color: transparent;
  color: #636363;
  border: 1px solid #f5f5f5;
  border-left: 3px solid #bcbec0;
  margin-bottom: -1px;
  transition: color 250ms ease-in-out, border-color 250ms ease-in-out, background-color 250ms ease-in-out;
}
.sitemap .sitemap-item a:hover, .sitemap .sitemap-item a:focus, .sitemap .sitemap-item a:active,
.sitemap .sitemap-item button:hover,
.sitemap .sitemap-item button:focus,
.sitemap .sitemap-item button:active {
  background-color: #f5f5f5;
  color: #00737a;
  border-left: 3px solid #00737a;
}
.sitemap .sitemap-item-depth1 a {
  padding-left: 1.25em;
}
.sitemap .sitemap-item-depth2 a {
  padding-left: 2.5em;
}
.sitemap .sitemap-item-depth3 a {
  padding-left: 3.75em;
}

.article .article__content {
  font-weight: 400;
}
.article .article-image__item {
  display: block;
  border-radius: 6px;
  overflow: hidden;
}
.article .article-image__item img {
  user-select: none;
  transition: opacity 1s, transform 1s;
}
.article .article-image__item img:hover, .article .article-image__item img:focus, .article .article-image__item img:active {
  opacity: 0.9;
  transform: scale3d(1.1, 1.1, 1);
}
.article .article-image__item.active img {
  animation: fadeIn 350ms ease-in;
  animation-fill-mode: forwards;
}
@media screen and (min-width: 48em) {
  @supports (display: grid) {
    .article {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: 3fr 2fr;
    }
  }
}

.enews-signup {
  background-color: #00737a;
  color: #ffffff;
  background-image: url(../../assets/img_bg_envelope-icon.png);
  background-position: center 10px;
  background-size: auto 151px;
  background-repeat: no-repeat;
  color: #FFF;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  padding: 50px 0;
}
.enews-signup a {
  color: inherit;
  opacity: 1;
}
.enews-signup a:hover, .enews-signup a:focus, .enews-signup a:active {
  color: inherit;
  opacity: 0.75;
}
.enews-signup h2, .enews-signup .h2,
.enews-signup h3, .enews-signup .h3 {
  color: inherit;
}
@media screen and (max-width: 575px) {
  .enews-signup {
    text-align: center;
  }
}
@media screen and (min-width: 48em) {
  .enews-signup .container > .row {
    align-items: center;
  }
}
@media screen and (min-width: 75em) {
  .enews-signup .container > .row {
    align-items: flex-end;
  }
}
.enews-signup__title {
  font-weight: 300;
  margin: 0;
  font-size: 32px;
  margin: 0;
  color: #FFFFFF !important;
}
.enews-signup__excerpt {
  font-size: 16px;
  margin-bottom: 10px;
  color: #FFFFFF !important;
}
@media screen and (min-width: 48em) {
  .enews-signup__excerpt {
    margin: 0;
  }
}
@media screen and (min-width: 22.5em) {
  .enews-signup form .form__column.field-element {
    flex: 1 1 auto;
    margin-bottom: 0;
    max-width: 100%;
  }
}
@media screen and (min-width: 22.5em) {
  .enews-signup form .form__column.field-element--submit {
    flex: 0 0 auto;
  }
}
.enews-signup form .field-element .textbox {
  padding-top: 13px;
  padding-bottom: 13px;
}
.enews-signup form .button[name=form_page_submit] {
  border-color: #ffffff;
  color: #ffffff;
  background-color: transparent;
}
.enews-signup form .button[name=form_page_submit]:hover, .enews-signup form .button[name=form_page_submit]:focus, .enews-signup form .button[name=form_page_submit]:active {
  border-color: #ffffff;
  color: #ffffff;
  background-color: transparent;
}
.enews-signup input[type=email] {
  border: none !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
  margin-right: 15px !important;
}
.enews-signup input[type=submit] {
  text-align: center;
  background: #00737a;
  border-color: #fff;
  color: #FFF;
  border-radius: 6px;
  padding: 13px 29px;
  line-height: 17px;
  font-size: 14px;
  display: block;
  width: 100%;
}
@media screen and (min-width: 22.5em) {
  .enews-signup input[type=submit] {
    display: inline-block;
    width: auto;
    flex: 0 0 auto;
    margin-left: 15px;
  }
}
.enews-signup input[type=submit]:hover {
  background: #666;
}

/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */