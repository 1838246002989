.splide {
    position: relative;
    width: 100%;
    margin-bottom: 60px;

    &__track {
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }

    &__slide {
        flex: 0 0 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    &__pagination {
        position: absolute;
        padding: 0;
        bottom: -48px;
        left: 0;
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;

        li {
            padding: 0 6px;
            display: block;
        }

        button {
            -webkit-appearance: none;
            background: transparent;
            outline: none;
            border: none;
            padding: 0;
            background-color: rgba($color-black, 0.5);
            box-shadow: 0 0 0 2px transparent;
            height: 11px;
            width: 11px;
            border-radius: 100%;

            transition:
                background-color 200ms ease,
                box-shadow 200ms ease;

            &:hover {
                background-color: $color-primary;
                box-shadow: 0 0 0 2px $color-white;
            }

            &:focus {
                background-color: $color-primary;
                box-shadow: 0 0 0 2px $color-white;
            }

            &.is-active {
                background-color: $color-black;
                box-shadow: 0 0 0 2px $color-white;
            }
        }
    }

    &__arrow {
        -webkit-appearance: unset;
        position: absolute;

        background: transparent;
        line-height: normal;
        outline: none;
        padding: 0;
        border: 0;

        width: 50px;
        height: 50px;
        z-index: calc(#{$header-index} - 1);
        top: 50%;
        margin-top: -25px;
        position: absolute;
        transition:
            border .3s ease-in-out,
            border-radius .2s ease-in-out,
            background-color .3s ease-in-out,
            transform .2s ease-in-out;

        border-radius: 25px;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -16px;
            width: 26px;
            height: 26px;
            border: 3px solid transparent;
            border-top: 3px solid rgba(255,255,255,0.75);
        }

        &:hover {
            transform: scaleX(1.2) scaleY(.7);
        }

        &--prev {
            left: 0;

            &:after {
                left: 20px;
                transform: rotate(-45deg);
                border-left: 3px solid rgba(255,255,255,0.75);
            }

            &:focus,
            &:active {
                &:after {
                    border-top-color: $color-white;
                    border-left-color: $color-white;
                }
            }
        }

        &--next {
            right: 0;

            &:after {
                right: 20px;
                transform: rotate(45deg);
                border-right: 3px solid rgba(255,255,255,0.75);
            }

            &:focus,
            &:active {
                &:after {
                    border-top-color: $color-white;
                    border-right-color: $color-white;
                }
            }
        }

        * {
            pointer-events: none;
        }

        svg {
            display: none;
        }
    }
}

[aria-label="gallery"]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px; /* compensates for 2px border */
}

.slider__item img {
    object-fit: cover;
}
