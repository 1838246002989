.explore-slider {

    &__title {
        text-align: center;
    }

    &__slider {
        position: relative;
        margin: 0 -15px;

        &__track {
            @include css-slider;
        }

        .slider__dots {
            display: none;
            position: static;
            justify-content: center;
            margin-top: 24px;

            a {
                &:before {
                    background-color: rgba($color-black, 0.5);
                }

                &.active:before {
                    background-color: $color-primary;
                }

                &:hover:before {
                    background-color: $color-black;
                }

                &:focus:before {
                    background-color: $color-black;
                }
            }
        }

        .slider__arrows {
            vertical-align: middle;
            display: inline-block;

            border: none;
            background: #bcbec0;
            color: $color-black;

            cursor: pointer;
            text-align: center;
            font-size: 13px;

            top: 0;
            bottom: 0;
            background: rgba(0,0,0,0.25);
            height: auto;
            width: var(--default-container-gutter);
            border-radius: 0;
            margin-top: 0;

            @include screen-1400 {
                border: 1px solid #bcbec0;
                background-color: $color-white;
                color: $color-white;
                border-radius: 32px;
                height: 32px;
                width: 32px;
                top: 50%;
                bottom: auto;

                margin-top: -16px;
            }

            &:after {
                content: none;
            }

            &.previous {
                right: calc(100% - 15px);
                left: auto;
                background: linear-gradient(to left, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);

                @include screen-1400 {
                    right: calc(100% + 14px);
                    background: #bcbec0;
                }
            }

            &.next {
                left: calc(100% - 15px);
                right: auto;
                background: linear-gradient(to right, rgba($color-white, 0) 0%, rgba($color-white, 1) 100%);

                @include screen-1400 {
                    left: calc(100% + 14px);
                    background: #bcbec0;
                }
            }

            &:hover {
                transform: none;
            }
        }

        &__item {
            flex: 0 0 100%;
            flex-shrink: 0;
            scroll-snap-align: start;
            padding: 0 15px;

            @include screen-768 {
                flex: 0 0 50%;
            }

            @include screen-992 {
                flex: 0 0 33.33333%;
            }
        }
    }

    &__cta {
        padding-top: 24px;
    }
}
