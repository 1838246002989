
.article {
    .article__media {

    }

    .article__content {
        font-weight: $fw-normal;
    }

    .article-image__item {
        display: block;
        border-radius: $standard-radius;
        overflow: hidden;

        img {
            user-select: none;
            transition:
                opacity 1s,
                transform 1s;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.9;
                transform: scale3d(1.1,1.1,1);
            }
        }

        &.active img {
            animation: fadeIn 350ms ease-in;
	        animation-fill-mode: forwards;
        }

    }

    @include screen-768 {
        @supports (display: grid) {
            display:grid;
            grid-gap: 32px;
            grid-template-columns: 3fr 2fr;
        }
    }

}


