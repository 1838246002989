.entry-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    &.columns-1 {
        gap: 0;
    }

    &.columns-2 {
        grid-template-columns: repeat(2, 1fr);

        @include screen-768 {
            column-gap: 4%;
        }
    }

    &.columns-3,
    &.columns-5,
    &.columns-6  {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    &.columns-4 {
        grid-template-columns: repeat(2, 1fr);

        @include screen-768 {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.columns-5 {
        @include screen-768 {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &.columns-6 {
        @include screen-768 {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    .card {
        padding: 50px 35px 35px;

        .button {
            order: 2;
            align-self: center;
            margin-top: 1em;
        }
    }
}
