.tile {
    background: $color-white;
}

.tile__inner {
    height: 100%;
}

.tile__img img {
    width: 100%;
}

.tile__content {
    padding: 20px 25px;
}

.tile__content {

}

.tile__content__heading {

}

