.enews-signup {
    background-color: $color-primary;
    @include reverse-text;


    background-image: url(../../assets/img_bg_envelope-icon.png);
    background-position: center 10px;
    background-size: auto 151px;
    background-repeat: no-repeat;

    color: #FFF;

    font-family: $accent-font;
    font-weight: 400;
    padding: 50px 0;

    @media screen and (max-width:575px) {
        text-align:center;
    }

    .container > .row {
        @include screen-768 {
            align-items: center;
        }

        @include screen-1200 {
            align-items: flex-end;
        }
    }

    &__title {
        font-weight:300;
        margin:0;
        font-size:32px;
        margin:0;
        color:#FFFFFF!important;
    }

    &__excerpt {
        font-size:16px;
        margin-bottom:10px;
        color:#FFFFFF!important;

        @include screen-768 {
            margin:0;
        }
    }

    form {
        .form__column.field-element {
            @include screen-360 {
                flex: 1 1 auto;
                margin-bottom: 0;
                max-width: 100%;
            }
        }

        .form__column.field-element--submit {
            @include screen-360 {
                flex: 0 0 auto;
            }
        }

        .field-element {
            .textbox {
                padding-top: 13px;
                padding-bottom: 13px;
            }
        }

        .button[name=form_page_submit] {
            border-color: $color-white;
            color: $color-white;
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: $color-white;
                color: $color-white;
                background-color: transparent;
            }
        }
    }

    input[type="email"]{
        border:none!important;
        -webkit-border-radius:6px!important;
        -moz-border-radius:6px!important;
        border-radius:6px!important;
        margin-right:15px!important
    }

    input[type="submit"] {
        text-align:center;
        background:#00737a;
        border-color: #fff;
        color:#FFF;
        border-radius:6px;
        padding: 13px 29px;
        line-height: 17px;
        font-size: 14px;
        display: block;
        width: 100%;

        @include screen-360 {
            display: inline-block;
            width: auto;
            flex: 0 0 auto;
            margin-left:15px;
        }

        &:hover {
            background:#666;
        }
    }
}
