.cta-box {
    background-color: $color-grey-01;
    padding: 32px;

    &__heading {
        margin-top: 0;

        span {
            display: block;
        }
    }

    &__subtitle {
        @include subtitle;
        font-size: var(--milli-font-size);

        @include screen-1400 {
            font-size: $font-body;
        }
    }

    &__title {
        @include h3;
        font-size: 150%;
        margin-bottom: 0.25em;

        @include screen-1400 {
            font-size: 200%;
            margin-bottom: 0.5em;
        }
    }

    &__cta {
        a {
            @include button;
        }
    }

    &__content {

        > *:last-child {
            margin-bottom: 0;
        }
    }
}
