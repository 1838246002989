.video-banner {
    position: relative;
}

.video-banner__video {
    display: block;
    background-color: $color-grey-07;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 56.25vw;

    @include screen-1920 {
        max-width: $max-page-width;
        max-height: calc(#{$max-page-width} * 0.5625);
    }

    &:before {
        content: '';
        display: block;
    }

    iframe {
        inset: 0 0 0 !important;
        width: 100vw !important;
        height: 56.25vw !important;

        @include screen-1920 {
            max-width: $max-page-width !important;
            max-height: calc(#{$max-page-width} * 0.5625) !important;
        }
    }
}
