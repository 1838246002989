.explore-item {
    display: block;

    &__link-wrap {
        color: inherit;
        overflow: hidden;
        border: none;

        &:hover,
        &:focus,
        &:active {
            .explore-item__picture img{
                transform:scale(1.2)
            }
        }

        &:focus,
        &:active {
            .explore-item__picture:after {
                right: 0;
            }
        }
    }

    &__picture {
        display: block;
        position: relative;
        border-radius: $standard-radius;
        overflow: hidden;
        background: $color-grey-07;
        background-image: url(../assets/logo.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;

        @supports(aspect-ratio: 3/2) {
            aspect-ratio: 3/2;
        }

        &:after {
            content: '\e619';

            text-indent: 0;
            line-height: normal;
            text-shadow: none;
            font-family: icomoon;
            font-size: 15px;
            color: #fff;
            -webkit-font-smoothing: antialiased;

            position: absolute;
            left: 0;
            right: 100%;
            top: 0;
            bottom: 0;

            overflow: hidden;

            display: block;
            opacity: .9;
            vertical-align: middle;
            margin: 0;
            background: rgba($color-black, 0.5);
            z-index: 1;

            transition:
                right 250ms ease;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                right: 0;
            }
        }

        img {
            transition:
                transform 250ms ease;

            @supports(aspect-ratio: 3/2) {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    h3 {
        font-weight: 500;
        color: #1e1e1e;
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 1em;
    }

    &__content {
        background-color: #FFF;
        padding: 30px 0 0 0;
        position: relative;
        z-index: 10;
        color: $color-grey-06;
    }

    &__copy {
        margin-top: 0;
        margin-bottom: 20px;
    }

    &__meta {
        list-style: none;
        margin: 0 0 13px;
        padding: 0;
        overflow: hidden;
        line-height: 14px;
        color: #333;
        font-size: 12px;

        i {
            margin-right: 5px;
            color: $color-grey-04;
        }

        span {
            display: inline-block;
            margin-right: 20px;
            color: #1e1e1e;
        }
    }
}

.explore-item.landscape {
    margin-bottom: 20px;

    .explore-item__link-wrap {
        display: flex;
        align-items: center;
    }

    .explore-item__picture {
        flex: 0 0 33.33333333%;

        @include screen-992 {
            flex-basis: 41.66666667%;
        }

        @include screen-1200 {
            flex-basis: 33.33333333%;
        }
    }

    .explore-item__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: 0 0 0 15px;

        .explore-item__meta {
            margin-bottom: 0;
            order: 1;
        }

        .explore-item__title {
            font-size: 18px;
            font-weight: 700;
            color: #1e1e1e;
            margin: 0.2em;
            white-space: normal;
            order: 0;
        }
    }
}

#room-template .explore-item.landscape .explore-item__title {
    font-size: 18px;
}

.explore-gallery {
    --gutter-width: 3rem;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));

    &__item {
        flex: 0 0 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
        padding: 0 var(--half-gutter-width);
        margin-bottom: var(--gutter-width);

        @include screen-560 {
            flex: 0 0 50%;
        }

        @include screen-992 {
            flex: 0 0 33.33333%;
        }
    }
}
