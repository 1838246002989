.testimonial-item {
    display: block;

    &__attribution {
        line-height: normal;
        position: relative;
        background-color: $color-black;
        color: $color-white;
        padding: 30px;
        margin-bottom: 0;

        &:after{
            position:absolute;
            left:35px;
            top:0;content:" ";
            height:0;
            width:0;
            pointer-events:none;
            border:medium solid transparent;
            border-top-color:#f6f6f6;
            border-width:10px;
        }

        &__name {
            display: block;
            font-size: 18px;
            color: #bcbec0;
            font-weight: $fw-normal;
        }

        &__job {
            &:after {
                content: " @ ";
            }
        }

        &__business {

        }
    }
}



