.inner-banner {
    background-color: $color-white;
    position: relative;

    @include screen-menu-desktop {
        min-height: var(--header-height);
    }

    &__img {
        background-color: $color-grey-07;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba($color-black, 0.3);
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include reverse-text;
        z-index: 1;
        padding-top: var(--header-height);
        left: var(--default-container-gutter);
        right: var(--default-container-gutter);

        .breadcrumb {
            margin: 0;
            color: $color-white;

            a {
                color: rgba($color-white, 0.8);

                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }
    }

    .page-header {
        margin-bottom: 0;
    }
}
