:root {
    --footerw_bg_image: ;
    --footerw_bg_full-size: initial;
    --footerw_bg_full-position: center center;
    --footerw_bg_repeat: no-repeat;
    --footerw_bg_pos: center center;
    --footer_bg_color: #1e1e1e;
    --footer_border_size: 12px;
    --footer_border_color: #e9eaee;
    --footer_link_color: $color-grey-04;
    --footer_link_color_hover: #ffffff;
}

.footer {
    background: var(--footer_bg_color);
    color: $color-grey-005;

    a {
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            color: $color-white;
        }
    }

    h3 {
        color: $color-grey-04;
    }
}

.footer .row {
    @include screen-768 {
        --gutter-width: 30px;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }
}

.footer__ankle .row .col-xs-12 {
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    @include screen-768 {
        margin-bottom: 0;

        &:first-child {
            margin-bottom: 40px;
        }
    }

    @include screen-992 {
        &:first-child {
            margin-bottom: 0;
        }
    }
}

/* Column one - logo & note */

.footer__logo {
    display: block;
    margin-bottom: 1em;

    .logo__img {
        max-width: 250px;
    }
}

.footer__note {
    color: $color-grey-005;

    > p:last-child {
        margin-bottom: 0;
    }

    .button {
        border-color: $color-grey-04;
        background: $color-grey-04;
        color: $color-grey-09;
        font-size: 13px;

        &:hover,
        &:focus,
        &:active {
            background: lighten($color-grey-04, 15%);
            border-color: lighten($color-grey-04, 15%);
            color: $color-grey-09;
        }
    }
}

/* Column two - link */
.footer__links {
    @include screen-992 {
        padding-left: 60px;
    }
}

.footer__linklist {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Column three - news */
.footer__news {
    display: block;
    text-decoration: none;

    &:focus {
        outline: thin dotted #fff;
    }

    &__title {
        font-size: var(--body-font-size);
        margin-bottom: 0;
        font-family: $primary-font;
        font-weight: $fw-normal;
    }
}

/* Column four - contact details */
.footer__contact {
    color: $color-grey-005;

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;

        line-height: 1.4;
        margin: 0 0 20px;
        position: relative;

        &:before {
            display: block;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            color: $color-grey-04;
            margin-right: 10px;
            margin-top: 0.2em;
        }

        &.address::before {
            content: "\f3c5";
        }

        &.phone {
            margin-bottom: 8px;

            &::before {
                content: "\f095";
            }
        }

        &.alternative-phone::before {
            content: " \0a00";
        }

        &.email {
            margin-bottom: 8px;

            &::before {
                content: "\f0e0";
            }
        }

        &.enquiry::before {
            content: " \0a00";
        }

        a {
            display: block;
        }

        &__title {
            @include vis-hidden;
        }
    }
}

.fusion-footer-copyright-area {
    --copyright_padding-top: 0;
    --copyright_padding-bottom: 0;
    --copyright_bg_color: #1e1e1e;
    --copyright_border_size: 1px;
    --copyright_border_color: #1e1e1e;
    --copyright_link_color_hover: #bcbec0;
}

.footer__toes {
    border-top: 1px solid #8d8d8d;
}

.footer__website-attribution {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: var(--milli-font-size);
    margin-left: -4px;
    margin-right: -4px;
    margin-bottom: 0;

    &__item {
        display: block;
        flex: 0 0 auto;
        padding-left: 4px;
        padding-right: 4px;
        transition: filter 250ms ease;

        @supports(filter: grayscale(1)){
            filter: grayscale(1);

            &:hover,
            &:focus,
            &:active {
                filter: grayscale(0);
            }
        }
    }

    &__img {
        width: 20px;
        height: auto;
    }

    &__note {
        display: block;
        flex: 0 0 100%;
        margin-bottom: 1em;
    }
}

.footer__text {
    padding-top: $spacing--medium;
    padding-bottom: $spacing--medium;
}

.footer__text p {
    margin: 0;
    display: inline;
}

.footer__text p:before {
    content: " | ";
}

.footer__text p:first-child:before {
    content: none;
}

/* BP small */
@include screen-768 {
    .section--pre-footer .block-list {
        -webkit-Column-count: 2;
           -moz-Column-count: 2;
                Column-count: 2;
        -webkit-Column-gap: 40px;
           -moz-Column-gap: 40px;
                Column-gap: 40px;
    }
    .section--footer {
        text-align: auto;
    }
    .footer__text {
        padding: 0;
    }
}
