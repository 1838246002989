.testimonial-faqs .row {
    --gutter-width: 4%;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

.testimonial__slider {
    .splide__arrows {
        display: none;
    }

    .splide__pagination {
        button {
            background-color: transparent;
            border: 1px solid $color-grey-006;
            box-shadow: 0 0 0 2px transparent;
            height: 12px;
            width: 12px;

            transition:
                background-color 200ms ease,
                border 200ms ease,
                box-shadow 200ms ease;

            &:hover {
                background-color: $color-grey-006;
                box-shadow: 0 0 0 2px transparent;
            }

            &:focus {
                background-color: $color-primary;
                box-shadow: 0 0 0 2px transparent;
            }

            &.is-active {
                background-color: $color-grey-006;
                box-shadow: 0 0 0 2px transparent;
            }
        }
    }
}
