
.stats-section {
    &__list {
        list-style-type:none;
        margin:0;
        padding:0;
        display:table;
        border:1px solid #8d8d8d;
        -webkit-border-radius:6px!important;
        -moz-border-radius:6px!important;
        border-radius:6px!important;
        overflow:hidden;
    }

    &__item {
        display:table-cell;
        text-align:center;
        vertical-align:middle;
        line-height: normal;
        border-right:1px solid #8d8d8d;
        font-size:13px;
        min-width:100px;
        padding:20px 10px;

        &:last-child{
            border:none
        }

        &__value {
            display:block;
            color:#bcbec0;
            font-size:28px;
            font-weight:700;

            @media screen and (max-width:991px){
                font-size:24px;
            }
        }
    }
}
