:root {
    --header-height: 118px;
    --header-height-shrink: 64px;
}

@include screen-menu-desktop() {
    :root {
        --header-height: 118px;
    }
}


#header {
    position: relative;
    z-index: $header-index;
    display: none;

    @include screen-menu-desktop {
        display: block;
    }

    @include screen-2400 {
        max-width: $max-page-width;
        margin: 0 auto;
    }
}

.header {
    position: absolute;
    left: 0;
    right: 0;
    top: var(--snav_height);
    height: var(--header-height);
    z-index: $header-index;
    background-color: rgba(0,0,0,0.75);
    transition:
        height $header-transition;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.fusion-logo {
    --logo_margin-top: 20px;
    --logo_margin-bottom: 20px;
    --logo_margin-left: 0px;
    --logo_margin-right: 0px;
}

#side-header .fusion-logo,
.fusion-header .fusion-logo {
    margin: var(--logo_margin-top) var(--logo_margin-right) var(--logo_margin-bottom) var(--logo_margin-left);

}

.fusion-logo img,
#side-header .fusion-logo,
.fusion-header .fusion-logo {
    max-height: 79px!important;
    width: 100%!important;
    max-width: 171px!important;
}

.header__logo {
    display: block;
    height: var(--header-height);
    transition:
        height $header-transition;

    @include screen-menu-mobile {
        height: calc(var(--header-height) - 40px);
    }

    @include screen-menu-desktop {
        height: var(--header-height);
    }

    svg {
        height: 100%;
    }
}

.header-sticky {
    .header {
        position: fixed;
        top: 0;

        @include screen-2400 {
            left: calc(50vw - (#{$max-page-width}/2));
            right: calc(50vw - (#{$max-page-width}/2));
        }
    }
}

.header-shrink {
    .header__logo {
        height: calc(var(--header-height-shrink) - 16px);
        top: 0;
    }
}


