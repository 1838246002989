.need-help-box {
    @include box;
    @include box-lined;
    overflow:hidden;

    p {
        margin-bottom: 1em;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0 0 6px;
            line-height: 2;

            &::before {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                color: #bcbec0;
                margin-right: 10px;
            }

            &.phone:before {
                content: "\f095";
            }

            &.email:before {
                content: "\f0e0";
            }

            &.enquiry:before {
                content: "\f1d8";
            }

            a {
                line-height: 1.4;
                color: #1e1e1e;
            }
        }
    }
}
